import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { Accordion, Nav, Row, Col, Image, Button } from 'react-bootstrap';
import ficha_user from '../../assets/img/pastilla2.png';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import SupportModal from '../Modals/supportModal';

const SupportBanner:FunctionComponent<{}> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [activedSupport, setActivedSupport] = useState(false);
  const [showBannerOrSupportButton, setShow] = useState(true);

  const checkForActivedSupport = async () => {
    try {
      const actived = (await ObexRequestHandler.get('/active_affiliate_support_plan',{},true)).data;

      setActivedSupport(actived);
      if (actived) {
        const supportScript = document.getElementById('ze-snippet') as HTMLScriptElement;

        if (!supportScript) { // si no existe el script
          const script = document.createElement('script');

          script.id = 'ze-snippet';
          script.src = 'https://static.zdassets.com/ekr/snippet.js?key=1d42ba61-1ecd-4371-bea3-6891df9e5892';
          script.async = true;
        
          document.body.appendChild(script);
        }
      }
      setShow(true);
    } catch (error) {
      console.error('ERROR GETTING ACTIVED SUPPORT ', error);
    }
  }

  useEffect(() => {
    checkForActivedSupport();  
    return () => {
      try {        
        //const supportScript = document.getElementById('ze-snippet') as HTMLScriptElement;
        //supportScript.parentElement.removeChild(supportScript);
        //const supportButton = document.getElementById('launcher') as HTMLIFrameElement;
        //supportButton.parentElement.removeChild(supportButton);
      } catch (error) {
        console.error('error removing support button')
      }
    }
  }, []);

  return (
    <Fragment >
      { (!activedSupport && showBannerOrSupportButton && SessionService.TipoUsuario==6) &&
        <Fragment>
          <div className="box-support mt-auto text-center px-4" style={{position: 'fixed', bottom: 0, width: '289px' }} >
            <Col md="5" className="my-3 mx-auto">
              <Image src={ficha_user}  fluid/>
            </Col>
            <Col md="12" className="title-box mb-3">
              <span>centuriapp<br></br></span>
              <span>soporte oficial</span>
            </Col>
            <Col md="12" className="text-box mb-3">
              <span>Soporte y servicio de la gente que mejor conoce los productos de centuriapp.</span>
            </Col>
            <Col md="12" className="mb-3">
              <Button className="obex-btn btn-green" onClick={() => setShowModal(true)}>Obtén soporte</Button>
            </Col> 
          </div>
          <SupportModal 
            shown={showModal} 
            onCloseAction={() => setShowModal(false)}
            onSuccess={checkForActivedSupport}
          />
        </Fragment>
      }
    </Fragment>
    
  )
};

export default SupportBanner;