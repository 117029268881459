import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Container, Row, Col,  Modal, Button, Form, Spinner, Card, Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { prependOnceListener } from 'process';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { parseToCoin } from '../../services/AmountService';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import SessionService from '../../services/SessionsService';
import { message } from 'antd';


type AddonsProps = {
  show: boolean,
  handleClose: any,
  nameProject: string,
  planID: string,
  priceMonthly: string,
  priceYear: string
}

const ModifyCommunicationModal:FunctionComponent<AddonsProps> = props => {

  const { show, handleClose, nameProject, planID, priceMonthly, priceYear } = props;


  const [pricePlanMontly, setPricePlanMontly] = useState(priceMonthly);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [projecstPlanInfo, setProjecstPlanInfo] = useState([]);
  const [requestsPlanInfo, setRequestsPlanInfo] = useState([]);
  const [selectedProject, setProject] = useState(0);
  const [selectedTx, setTx] = useState(0);
  const [projectPlanPriceMonthly, setProjectPriceMonthly] = useState(0);
  const [projectPlanPriceYear, setProjectPriceYear] = useState(0);
  const [txPlanPriceMonthly, setTxPriceMonthly] = useState(0);
  const [txPlanPriceYear, setTxPriceYear] = useState(0);
  const [txDesc, setTxDesc] = useState('');
  const [projectDesc, setProjectDesc] = useState('');
  var [Message, setMessage] = useState(priceYear);
  const [sending, setSending] = useState(false);
  var [Subject, setSubject] = useState(priceMonthly);
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const history = useHistory();

const Cierra = (e) =>{
  setMessage('');
  setSubject('');
  handleClose()
}

  const changeSubject = (e) => {
    const newSubject = e.target.value;
    setSubject(newSubject);
  }

  const changeMessage = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
  }




  const handleSubmitMessage = async () => {
    try {

      setSending(true);
      const result = await ObexProjectsRequestHandler.get(`/ws/notificaciones.py/gestor_notificaciones?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&comando=crear&asunto=${priceMonthly}&texto=${priceYear}&destino=${planID}`);
      const {status,message} = result
      if (status){
        setSuccess("El mensaje ha modificado con éxito.");
        setMessage('');
        setSubject('');
      }
      console.log(result)
      setSending(false);
      //handleClose();

    } catch (error) {
      console.error('ERROR SENDING MESSAGE ', error);
      setError(error)
    }

    setTimeout(function() { 
      setSuccess('');
      setError('');
      handleClose();
      }.bind(this), 2000)

  }



  useEffect(() => {
    setMessage('');
    setSubject('');
  }, [])
  
  return(

    

    <Modal dialogClassName="addons-dialog w-75 obex-dialog py-3 obex-dialog"
      show={show} 
      onHide={Cierra}
      animation={false}>
      <Modal.Header closeButton className="border-0 pb-0 pt-4">
        <Modal.Title className=" w-100">
          <Row>
            <Col className="addons-title">
              <span >Mensaje enviado a {nameProject}</span>
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col md="10" className="mx-auto  modal-subtitle">

            <Form className="obex-form">
            <Form.Group></Form.Group>
            Asunto
                  <Form.Group controlId="formBasicName">
                    <Form.Control required placeholder="Asunto del mensaje" onChange={changeSubject} value={Subject || priceMonthly}/>
                  </Form.Group>
            Mensaje
            <Form.Group controlId="formBasicMessage">

              <Form.Control required as="textarea" rows={6} placeholder="Mensaje a enviar" onChange={changeMessage} value={Message || priceYear} />
              </Form.Group>

              <Form>
<Card>
  <div key={`inline-${'checkbox'}`} className="pl-3">
      <Form.Check
        inline
        checked
        disabled
        label="Interna"
        name="group1"
        type={'checkbox'}
        id={`inline-${'checkbox'}-1`}
      />
      <Form.Check
        inline
        disabled
        label="email"
        name="group1"
        type={'checkbox'}
        id={`inline-${'checkbox'}-2`}
      />
      <Form.Check
        inline
        disabled
        label="sms"
        type={'checkbox'}
        id={`inline-${'checkbox'}-3`}
      />
        <Form.Check
        inline
        disabled
        label="push"
        type={'checkbox'}
        id={`inline-${'checkbox'}-4`}
      />
    </div>
</Card>
</Form>

            </Form>

            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-4">
        <Container fluid>

          <Row className="mb-2">
            <Col className="text-center">
              
              <Button className="btn-green with-add" onClick={handleSubmitMessage}>
              {sending ? "Modificando" : "Modificar"}
                {sending && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
              </Button>
               
            </Col>
          </Row>

          {error.length > 0 && (
                <Alert variant='danger'>
                  {error}
                </Alert>)}
          {success.length > 0 && (
                <Alert variant='success'>
                  {success}
                </Alert>)}   



        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ModifyCommunicationModal;