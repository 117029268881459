import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../../basicComponents/buttonSubmit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye, faEdit, faShare, faShareAlt, faEraser, faTrash } from '@fortawesome/free-solid-svg-icons';
import ObexAlert from '../../basicComponents/ObexAlerts';
import { validatePass } from '../../../services/validationService';
import SubsSuccessContent from './subscriptionSuccess';
import SessionService from '../../../services/SessionsService';
import ReadCommunicationModal from '../../ClientsComponents/ReadCommunication';
import ModifyCompanyAddress from '../../ClientsComponents/ModifyCompanyAddres';
import ModifyCompanyName from '../../ClientsComponents/ModifyCompanyName';
import ModifyCompanyLogo from '../../ClientsComponents/ModifyCompanyLogo';
import PlantillaQR from '../../assets/img/plantillaQR.png';
import ObexProjectsRequestHandler from '../../../handlers/ObexProjectsRequestsHandler';


type CreateSubsContentProps = {
  handleProjectsNumbers: any,
  handleMonthRateLimit: any,
  handleNameSubs: any,
  handleMail: any,
  handleProject: any,
  handleStaging: any,
  handleCipher: any,
  error: any,
  success: any,
  loading: any,
  submitDisabled: any,
  handleSubmit: any,
  cipher: boolean,
  project: boolean,
  staging: boolean
}


const CreateSubsContent:FunctionComponent<CreateSubsContentProps> = (props) => {

  const { handleProjectsNumbers, handleMonthRateLimit, handleNameSubs, 
          handleMail, handleProject, handleStaging, handleCipher, error, 
          success, loading, submitDisabled, handleSubmit, cipher, project, staging} = props;


  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose = () => {
    
    setShow2(false);
    setShow3(false);
    setShow(false);
  
  }
  const handleEditAddres = () => setShow(true);
  const handleEnterpriseName = () => setShow2(true);
  const handleEnterpriseLogo = () => setShow3(true);
  const handleAddres = (address) => setEnterpriseAddress(address);
  const handlePhone = (phone) => setEnterprisePhone(phone);
  const handleEnName = (name) => setEnterpriseName(name);
  const handleEnLogo = (url) => setEnterpriseLogo(url);
  const [enterpriseName, setEnterpriseName] = useState(SessionService.DatosEmpresa["company_name"]);
  const [enterprisePhone, setEnterprisePhone] = useState(SessionService.DatosEmpresa["telefono_notificacion"]);
  const [enterpriseAddress, setEnterpriseAddress] = useState(SessionService.DatosEmpresa["company_address"] +", "+SessionService.DatosEmpresa["company_localidad"] +", "+ SessionService.DatosEmpresa["company_provincia"] + " ("+SessionService.DatosEmpresa["company_cp"]+")");
  
  const [Address, setAddress] = useState(SessionService.DatosEmpresa["company_address"])
  const [Localidad, setLocalidad] = useState(SessionService.DatosEmpresa["company_localidad"])
  const [Provincia, setProvincia] = useState(SessionService.DatosEmpresa["company_provincia"])
  const [CP, setCP] = useState(SessionService.DatosEmpresa["company_cp"])
  
  const [enterpriseLogo, setEnterpriseLogo] = useState(SessionService.Logo);
  const [clients, setClients] = useState(Array());
  const [stillLoaded, SetStillLoaded] = useState(false);
  const [error2, setError2] = useState('');
  const [success2, setSuccess2] = useState(false);
  const [loading2, setLoading2] = useState(false);


  const getLocations = async () => {
    setLoading2(true);
    SetStillLoaded(true);
    try {
      const {status, resultado, message } = await ObexProjectsRequestHandler.get(`/ws/employee_time_control.py/get_locations?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29`);

      if (!status) throw { message };
      const clientsList = resultado.sort((a, b) => a.id > b.id ? 1 : -1);

      setClients(clientsList);
    } catch (error) {
      setError2(error.message || error)
      setLoading2(false);
      SetStillLoaded(false);
    };
    SetStillLoaded(false);
  };




  useEffect(() => {
    getLocations();
    
  },[])

  return(
    
    <>
    
    <ModifyCompanyAddress
      show={show}
      handleClose={handleClose}
      handleAddres = {handleAddres}
      handlePhone = {handlePhone}
      nameProject={SessionService.DatosEmpresa["company_name"]}
      planID={"clientIDToSend"}
      telefono_notificaciones={enterprisePhone}
      Direccion={Address}
      Localidad={Localidad}
      Provincia={Provincia}
      CP={CP}
      
      />

    <ModifyCompanyName
      show={show2}
      handleClose={handleClose}
      handleEnName = {handleEnName}
      nameProject={SessionService.DatosEmpresa["company_name"]}
      planID={"clientIDToSend"}
      priceMonthly={enterpriseName}
      priceYear={enterpriseAddress} />  

    
    <ModifyCompanyLogo
      show={show3}
      handleClose={handleClose}
      handleEnName = {handleEnLogo}
      nameProject={SessionService.DatosEmpresa["company_name"]}
      planID={"clientIDToSend"}
      priceMonthly={enterpriseLogo}
      priceYear={enterpriseAddress} />  
      
      
      <Row>
        <Col md="9" className="mx-auto">

          <Row>
            <Col>
              <Row>
                <Col md={9}>
              <h1 className="mb-3">{enterpriseName}</h1>
              </Col>
              <Col  md={3}>
              <button className="btn-transparent" style={{ float: 'right', transform: 'translate(-15px,50%)'}}><FontAwesomeIcon icon={faEdit} onClick={handleEnterpriseName}/> </button>
              </Col>
              </Row>
            </Col>
          </Row>


          <Row>
            <Col>
              <Card className="product-detail-card mb-3">
                <Card.Body className="p-3">
                  <button className="btn-transparent" style={{ float: 'right' }}><FontAwesomeIcon icon={faEdit} onClick={handleEnterpriseLogo}/> </button>
                  <Row>
                    <Col className="mx-auto d-block text-center">
                      <Form className="obex-form">
                        <Form.Row className="mb-3 mx-auto d-block">

                          <img id='logo'
                            src={SessionService.Logo}
                            alt=""
                            title="Logo de la empresa"
                            width="200"
                            height="100" />

                        </Form.Row>
                      </Form>

                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>


          <Row>
            <Col>
              <Card className="product-detail-card mb-3">
                <Card.Body className="p-3">
                  <button className="btn-transparent" style={{ float: 'right' }}><FontAwesomeIcon icon={faEdit} onClick={handleEditAddres}/> </button>
                  <Row>
                    <Col className="mx-auto d-block text-center">
                      <Form className="obex-form">
                        <Form.Row className="mb-3 mx-auto d-block">
                          {enterpriseAddress}
                        </Form.Row>
                        <Form.Row className="mb-3 mx-auto d-block">
                          Teléfono para notificaciones: {enterprisePhone}
                        </Form.Row>

                      </Form>

                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Card className="product-detail-card mb-3">
            <Card.Body className="p-3">
              <button className="btn-transparent" style={{ float: 'right' }}><FontAwesomeIcon icon={faShareAlt} onClick={()=>{
                
                var codigo_qr = '<img src="'+SessionService.QRp+'" style="padding-top:467px; padding-left:1136px;"/>'
                var telefono = '<a style="padding-top:304px; padding-left:449px; "><H1 style="padding-top:304px; padding-left:449px; ">'+SessionService.DatosEmpresa["telefono_notificacion"]+'</H1></a>'
                //window.print()
                var divContents = document.getElementById("barcode1").innerHTML;
                var a = window.open('', '', 'width=1735, height=1227');
                a.document.write('<html width="1735" height="1227">');
                a.document.write('<style> html, body { width: 1735px; height: 1227px; background-repeat: no-repeat;}</style>')
                a.document.write('<body background="/plantillaQRV3.png" >');
                a.document.write(codigo_qr);
                a.document.write(telefono);
                a.document.write('</body></html>');
                a.document.close();
                //
                setTimeout(function() { 
                  a.print();
                  }.bind(this), 2000)
                
                }}/> </button>
              <Row>
                <Col className="mx-auto d-block text-center">
                  <Form className="obex-form">

                    <Form.Row className="mb-3 mx-auto d-block text-center">
                    <div id="barcode1">
                      <img id='barcode'
                        src={SessionService.QR}
                        alt=""
                        title="Codigo QR de la empresa"
                        width="200"
                        height="200" />
                    </div>
                    </Form.Row>

                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row></>
  )
}



type AccountProps = {
}

const CreateSubcription:FunctionComponent<AccountProps> = (props) => {

  const [ projectsNumbers, setProjectsNumbers ] = useState('');
  const [ monthRateLimit, setMonthRateLimit ] = useState('');
  const [ nameSubs, setNameSubs ] = useState('');
  const [ project, setProject] = useState(false);
  const [ staging, setStaging ] = useState(false);
  const [ cipher, setCipher ] = useState(false);
  const [ mail, setMail ] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ submitDisabled, setSubmitDisabled ] = useState(true);
  const [ createdSuccess, setCreatedSuccess ] = useState(false);
  const [ userInfo, setUserInfo ] = useState({});
  const [codigoQr, setCodigo] = useState("Hello");

  
  const handleProjectsNumbers = (e) => {setProjectsNumbers(e.target.value)}
  const handleMonthRateLimit = (e) => {setMonthRateLimit(e.target.value)}
  const handleNameSubs = (e) => {setNameSubs(e.target.value)}
  const handleMail = (e) => {setMail(e.target.value)}
  const handleProject = (e) => {setProject(!project)};
  const handleStaging = (e) => {setStaging(!staging)};
  const handleCipher = (e) => {setCipher(!cipher)};

  const setQR = (codigo) => {
    var cadena = "https://api.qrserver.com/v1/create-qr-code/?data="+codigo+"&amp;size=500x500"
    setCodigo(cadena)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setError('');
    setLoading(true);
    const subscription = await handleSubs();
    console.log('subscription ', subscription)
    const { id } = subscription;
    setUserInfo(await handleCustomUser(id));
    setCreatedSuccess(true);
    setLoading(false);
  };

  const handleSubs = async () => {
    try {
      const payload = {
        projects_numbers: projectsNumbers,
        month_rate_limit: monthRateLimit,
        name: nameSubs
      }
      const { success, data, message } = await ObexRequestsHandler.post('/subscriptions', payload);
      if (!success) throw { message };   
      return data;
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
    }

  }

  const handleCustomUser = async (subscription) => {
    try {
      const payload = {
        email: mail,
        subscription: subscription,
        project: project,
        staging: staging,
        cipher: cipher
      }
      const { success, data, message } = await ObexRequestsHandler.post('/create_custom_user', payload);
      if (!success) throw { message };   
      return data;
    } catch (error) {
      setError(error.message || error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (projectsNumbers && monthRateLimit && nameSubs && mail){
      setQR(SessionService.DatosEmpresa["company_code"])
      setSubmitDisabled(false);
    }else{
      setSubmitDisabled(true);
    }
  },[ projectsNumbers, monthRateLimit, nameSubs, mail ])

  return (
    <DashLayout sider={DashRouters} active="enterprise">
      {createdSuccess ? 
      <SubsSuccessContent 
        userInfo={userInfo}
      />:
      <CreateSubsContent 
        handleProjectsNumbers={handleProjectsNumbers}
        handleMonthRateLimit={handleMonthRateLimit}
        handleNameSubs={handleNameSubs}
        handleMail={handleMail}
        handleProject={handleProject}
        handleStaging={handleStaging}
        handleCipher={handleCipher}
        error={error}
        success={success}
        loading={loading}
        submitDisabled={submitDisabled}
        handleSubmit={handleSubmit}
        cipher={cipher}
        project={project}
        staging={staging}
      />}
    </DashLayout>
  );
}

export default CreateSubcription;