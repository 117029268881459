import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Container, Row, Col,  Modal, Button, Form, Spinner, Card, Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { prependOnceListener } from 'process';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { parseToCoin } from '../../services/AmountService';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import SessionService from '../../services/SessionsService';
import { message } from 'antd';
import { exception } from 'console';


type AddonsProps = {
  show: boolean,
  handleClose: any,
  handleAddres: any,
  handlePhone: any,
  nameProject: string,
  planID: string,
  telefono_notificaciones: string,
  Direccion: string,
  Localidad: string,
  Provincia: string,
  CP: string
}

const ModifyCompanyAddress:FunctionComponent<AddonsProps> = props => {

  const { show, handleClose, handleAddres, handlePhone, nameProject, planID, telefono_notificaciones, Direccion, Localidad, Provincia, CP } = props;


  const [pricePlanMontly, setPricePlanMontly] = useState(telefono_notificaciones);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [projecstPlanInfo, setProjecstPlanInfo] = useState([]);
  const [requestsPlanInfo, setRequestsPlanInfo] = useState([]);
  const [selectedProject, setProject] = useState(0);
  const [selectedTx, setTx] = useState(0);
  const [projectPlantelefono_notificaciones, setProjecttelefono_notificaciones] = useState(0);
  const [projectPlanDireccion, setProjectDireccion] = useState(0);
  const [txPlantelefono_notificaciones, setTxtelefono_notificaciones] = useState(0);
  const [txPlanDireccion, setTxDireccion] = useState(0);
  const [txDesc, setTxDesc] = useState('');
  const [projectDesc, setProjectDesc] = useState('');
  var [Message, setMessage] = useState(Direccion);
  const [sending, setSending] = useState(false);
  var [Subject, setSubject] = useState(telefono_notificaciones);
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  var [localidad, setLocalidad] = useState(Localidad);
  var [provincia, setProvincia] = useState(Provincia);
  var [cp, setCP] = useState(CP);

  const history = useHistory();

const Cierra = (e) =>{
  setMessage('');
  setSubject('');
  handleClose()
}

  const changeSubject = (e) => {
    const newSubject = e.target.value;
    setSubject(newSubject);
  }


const handleEnteredPhone= (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    try{
      var valor = Number(value)
      var valor2 = valor.toString();
    if (valor2.length<10 && valor2!="NaN")
      { 
        if (valor==0) valor2='';
        setSubject(valor2);
        
      }
    }
    finally{

    }
  }


  const handleEnteredCP= (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    try{
      var valor = Number(value)
      var valor2 = valor.toString();
    if (valor2.length<6 && valor2!="NaN")
      { 
        if (valor==0) valor2='';
        setCP(valor2);
        
      }
    }
    finally{

    }
  }


  const changeMessage = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
  }

  const changeLocalidad = (e) => {
    const newMessage = e.target.value;
    setLocalidad(newMessage);
  }

  const changeProvincia = (e) => {
    const newMessage = e.target.value;
    setProvincia(newMessage);
  }

  const changeCP = (e) => {
    const newMessage = e.target.value;
    setCP(newMessage);
  }



  const handleSubmitMessage = async () => {

    setSending(true);
    try{


      var ServiciosCentrales = -1

      const {status, resultado, message } = await ObexProjectsRequestHandler.get(`/ws/employee_time_control.py/get_locations?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29`);

      if (!status) throw { message };
      const clientsList = resultado.sort((a, b) => a.id > b.id ? 1 : -1);

      clientsList.forEach(element => {
        if (element.central) ServiciosCentrales = element.id;
      });


      const dirConsulta = Message+", "+localidad+", "+provincia+", "+cp
      const dirActualiza = Message+", "+localidad+", "+provincia
      const Nombre = "Servicios Centrales"
      const resltAdress = await ObexProjectsRequestHandler.get(`/ws/geopositioning.py/address_coordinates?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&address=${dirConsulta}`);

      console.log(resltAdress)
      console.log(resltAdress.location.lat)
      console.log(resltAdress.location.lng)
      if (!resltAdress.status) throw("ERROR EN CONSULTA DE DIRECCION")

      const latitude = resltAdress.location.lat;
      const longitude = resltAdress.location.lng

      if (ServiciosCentrales!=-1){
      const resultModify = await ObexProjectsRequestHandler.get(`/ws/employee_time_control.py/edit_location?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&address=${dirActualiza}&cp=${cp}&latitude=${latitude}&longitude=${longitude}&location_id=${ServiciosCentrales}`);
      
      if (!resultModify.status) throw ("ERROR EN LA MODIFICACION DE LA DIRECCION");
    
      }
      else {

      const resultCreate = await ObexProjectsRequestHandler.get(`/ws/employee_time_control.py/add_location?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&address=${dirActualiza}&name=${Nombre}&cp=${cp}&latitude=${latitude}&longitude=${longitude}&mindist=500`);

      if (!resultCreate.status) throw ("ERROR EN LA CREACION DE LA DIRECCION");
      }

    }catch{
      setError('Dirección No Válida, Google no puede geoposicionarla');
      setSending(false);
      setTimeout(function() { 
        setSuccess('');
        setError('');
        setMessage(SessionService.DatosEmpresa["company_address"]);
        setLocalidad(SessionService.DatosEmpresa["company_localidad"]);
        setProvincia(SessionService.DatosEmpresa["company_provincia"]);
        setCP(SessionService.DatosEmpresa["company_cp"]);
        setSubject(SessionService.DatosEmpresa["telefono_notificacion"])
        
        handleClose();
        }.bind(this), 2000)
    }



    try {

      setSending(true);
      const result = await ObexProjectsRequestHandler.get(`/ws/users.py/data?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&telefono_notificacion=${Subject}&company_address=${Message}&company_localidad=${localidad}&company_provincia=${provincia}&company_cp=${cp}&save=true`);

      const {status,message} = result
      if (status){
        setSuccess("Datos modificados con éxito.");
        setMessage('');
        setSubject('');
      }

      setSending(false);
      //handleClose();

    } catch (error) {

      setError(error)
    }
    SessionService.DatosEmpresa["telefono_notificacion"] = Subject
    SessionService.DatosEmpresa["company_address"] = Message
    SessionService.DatosEmpresa["company_localidad"] = Localidad
    SessionService.DatosEmpresa["company_provincia"] = Provincia
    SessionService.DatosEmpresa["company_cp"] = CP
    handlePhone(Subject);
    handleAddres(Message+", "+localidad+", "+provincia+", ("+cp+")")
    setTimeout(function() { 
      setSuccess('');
      setError('');

      handleClose();
      }.bind(this), 2000)


setSending(false);
  }



  useEffect(() => {
    setMessage(Direccion);
    setSubject(telefono_notificaciones);
  }, [])
  
  return(

    

    <Modal dialogClassName="addons-dialog w-75 obex-dialog py-3 obex-dialog"
      show={show} 
      onHide={Cierra}
      animation={false}>
      <Modal.Header closeButton className="border-0 pb-0 pt-4">
        <Modal.Title className=" w-100">
          <Row>
            <Col className="addons-title">
              <span >Modificar datos de {nameProject}</span>
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col md="10" className="mx-auto  modal-subtitle">

            <Form className="obex-form">
            <Form.Group></Form.Group>
            Teléfono para notificaciones
                  <Form.Group controlId="formBasicName">
                    <Form.Control required placeholder="Teléfono para notificaciones" onChange={handleEnteredPhone} value={Subject}/>
                  </Form.Group>
            
            Dirección
            <Form.Group controlId="formBasicMessage">
              <Form.Control required placeholder="Nueva dirección" onChange={changeMessage} value={Message} />
            </Form.Group>

            Localidad
            <Form.Group controlId="formBasicMessage">
              <Form.Control required placeholder="Localidad" onChange={changeLocalidad} value={localidad} />
            </Form.Group>

            Provincia
            <Form.Group controlId="formBasicMessage">
              <Form.Control required placeholder="Provincia" onChange={changeProvincia} value={provincia} />
            </Form.Group>

            Código Postal
            <Form.Group controlId="formBasicMessage">
              <Form.Control required placeholder="Código Postal" onChange={handleEnteredCP} value={cp} />
            </Form.Group>
            

              

            </Form>

            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-4">
        <Container fluid>

          <Row className="mb-2">
            <Col className="text-center">
              
              <Button className="btn-green with-add" onClick={handleSubmitMessage}>
              {sending ? "Modificando" : "Modificar"}
                {sending && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
              </Button>
               
            </Col>
          </Row>

          {error.length > 0 && (
                <Alert variant='danger'>
                  {error}
                </Alert>)}
          {success.length > 0 && (
                <Alert variant='success'>
                  {success}
                </Alert>)}   



        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ModifyCompanyAddress;