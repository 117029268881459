import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Container, Row, Col,  Modal, Button, Form, Spinner, Card, Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { prependOnceListener } from 'process';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { parseToCoin } from '../../services/AmountService';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import SessionService from '../../services/SessionsService';
import { message } from 'antd';


type AddonsProps = {
  show: boolean,
  handleClose: any,
  nameProject: string,
  planID: string,
  priceMonthly: string,
  priceYear: string
}

const AddLocationModal:FunctionComponent<AddonsProps> = props => {

  const { show, handleClose, nameProject, planID, priceMonthly, priceYear } = props;


  const [pricePlanMontly, setPricePlanMontly] = useState(priceMonthly);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [projecstPlanInfo, setProjecstPlanInfo] = useState([]);
  const [requestsPlanInfo, setRequestsPlanInfo] = useState([]);
  const [selectedProject, setProject] = useState(0);
  const [selectedTx, setTx] = useState(0);
  const [projectPlanPriceMonthly, setProjectPriceMonthly] = useState(0);
  const [projectPlanPriceYear, setProjectPriceYear] = useState(0);
  const [txPlanPriceMonthly, setTxPriceMonthly] = useState(0);
  const [txPlanPriceYear, setTxPriceYear] = useState(0);
  const [txDesc, setTxDesc] = useState('');
  const [projectDesc, setProjectDesc] = useState('');
  var [Message, setMessage] = useState(priceYear);
  const [sending, setSending] = useState(false);
  var [Subject, setSubject] = useState(priceMonthly);
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')


  const [Nombre, setNombre] = useState('');
  const [Direccion, setDireccion] = useState('');
  const [CP, setCP] = useState('');
  const [mindist, setMindist] = useState(500);
  const [Position, setPosition] = useState('');

  const history = useHistory();

const Cierra = (e) =>{
  setMessage('');
  setSubject('');
  handleClose()
}

  const changeSubject = (e) => {
    const newSubject = e.target.value;
    setSubject(newSubject);
  }

  const changeMessage = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
  }

  const changeCP = (e) => {
    const newMessage = e.target.value;
    setCP(newMessage);
  }

  const changeMD = (e) => {
    const newMessage = e.target.value;
    if(isNaN(newMessage)) setMindist(0);
    else setMindist(newMessage);
  }

  const changeDireccion = (e) => {
    const newMessage = e.target.value;
    setDireccion(newMessage);
  }
  const changePosition = (e) => {
    const newMessage = e.target.value;
    setPosition(newMessage);
  }
  const changeNombre = (e) => {
    const newMessage = e.target.value;
    setNombre(newMessage);
  }



  const handleSubmitMessage = async () => {
   

      setSending(true);
      try {
      var latitude = 0;
      var longitude = 0;

      const resltAdress = await ObexProjectsRequestHandler.get(`/ws/geopositioning.py/address_coordinates?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&address=${Direccion}`);

      var { status, location, message } = resltAdress;
      if (status){
        latitude = location["lat"];
        longitude = location["lng"];
      }
      else {

      }

      console.log(resltAdress);

    } catch (error) {
      console.error('ERROR GETTING POSITIONING ', error);
      setError(error)
    }

    try{
      const result = await ObexProjectsRequestHandler.get(`/ws/employee_time_control.py/add_location?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&address=${Direccion}&name=${Nombre}&cp=${CP}&latitude=${latitude}&longitude=${longitude}&mindist=${mindist}`);
      var {status,message} = result
      if (status){
        setSuccess("La ubicación se ha añadido con éxito.");
        setMessage('');
        setSubject('');
      }
      console.log(result)
      setSending(false);
      //handleClose();

    } catch (error) {
      console.error('ERROR SENDING MESSAGE ', error);
      setError(error)
    }

    setTimeout(function() { 
      setSuccess('');
      setError('');
      handleClose();
      }.bind(this), 2000)

  }



  useEffect(() => {
    setMessage('');
    setSubject('');
  }, [])
  
  return(

    

    <Modal dialogClassName="addons-dialog w-75 obex-dialog py-3 obex-dialog"
      show={show} 
      onHide={Cierra}
      animation={false}>
      <Modal.Header closeButton className="border-0 pb-0 pt-4">
        <Modal.Title className=" w-100">
          <Row>
            <Col className="addons-title">
              <span >Agregar dirección</span>
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col md="10" className="mx-auto  modal-subtitle">

            <Form className="obex-form">
            Nombre
          <Form.Group controlId="formBasicName">
            <Form.Control required placeholder="Nombre de la ubicación" onChange={changeNombre} value={Nombre || ""}/>
          </Form.Group>
            CP
          <Form.Group controlId="formBasicName">
            <Form.Control required placeholder="Código postal" onChange={changeCP} value={CP || ""}/>
          </Form.Group>

            <Form.Group></Form.Group>
            Dirección
          <Form.Group controlId="formBasicName">
            <Form.Control required placeholder="Dirección de la ubicación" onChange={changeDireccion} value={Direccion || ""}/>
          </Form.Group>

          
          Distancia mínima en metros
          <Form.Group controlId="formBasicName">
            <Form.Control required placeholder="Distancia mínima" onChange={changeMD} value={mindist || ""}/>
          </Form.Group>


            </Form>

            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-4">
        <Container fluid>

          <Row className="mb-2">
            <Col className="text-center">
              
              <Button className="btn-green with-add" onClick={handleSubmitMessage}>
              {sending ? "Agregando" : "Agregar"}
                {sending && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
              </Button>
               
            </Col>
          </Row>

          {error.length > 0 && (
                <Alert variant='danger'>
                  {error}
                </Alert>)}
          {success.length > 0 && (
                <Alert variant='success'>
                  {success}
                </Alert>)}   



        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default AddLocationModal;