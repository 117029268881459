import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../../configs/routers/dashboard-routers-private.json';
import { Row, Col, Alert, Card, Button, Form, InputGroup, FormControl, AlertProps, Spinner } from 'react-bootstrap';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../../basicComponents/buttonSubmit';
import { validatePass, validateRepeatPass } from '../../../services/validationService';
import ObexAlert from '../../basicComponents/ObexAlerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import ObexProjectsRequestHandler from '../../../handlers/ObexProjectsRequestsHandler';
import SessionService from '../../../services/SessionsService';

type InputProps = {
  handleChange: (e) => void,
  handleValidate: (e) => void,
  placeholder: string,
  value: string
}

const InputPassword:FunctionComponent<InputProps> = (props) => {

  const { handleChange, handleValidate, placeholder, value } = props;
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {setShowPassword(!showPassword)};

  return (
    <InputGroup className="obex-input-group">
      <FormControl
        placeholder={placeholder}
        aria-describedby="password"
        type={showPassword ? 'text' : 'password'}
        onChange={handleChange} onBlur={handleValidate}
        value={value}
      />
      <InputGroup.Append>
        <Button onClick={handleShowPassword} className="text-black">
          {showPassword ? 
            <FontAwesomeIcon icon={faEyeSlash}/> : 
            <FontAwesomeIcon icon={faEye}/>}
            </Button>
      </InputGroup.Append>
    </InputGroup>
  )
}


type ChangePassProps = {
  accountName: string,
}

const ChangePasswordSetting:FunctionComponent<{}> = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const [actualPass, setActualPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [repeatPass, setRepeatPass] = useState('');

  const handleActualPass = (e) => {setActualPass(e.target.value)}
  const handleNewPass = (e) => {setNewPass(e.target.value)}
  const handleRepeatPass = (e) => {setRepeatPass(e.target.value)}

  const handleSubmit = async(e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setError('');
      setSuccess('');
      setLoading(true);
      
      const { status,resultado,message} = await ObexProjectsRequestHandler.get(`/ws/users.py/change_password?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&password=${actualPass}&new_password=${newPass}&repeat_new_password=${repeatPass}`);

      if (status) {
        setSuccess("Contraseña cambiada con exito");
        setActualPass('');
        setNewPass('');
        setRepeatPass('');
        setTimeout(function() { 
          setSubmitDisabled(true);
          setSuccess("");
          }.bind(this), 1000);
      } else {
        setError(message);
        setTimeout(function() { 
          setSubmitDisabled(true);
          setError("");
          }.bind(this), 1000);
      }
    } catch (error) {
      console.error('ERROR WHILE SAVING ', error)
      setError(error.message || error);
    }
    setLoading(false);
  }

  const handleValidatePass = (e) => {
    if (!validatePass(e.target.value)){
      setSubmitDisabled(true);
      setError('La contraseña ha de ser de al menos 8 carateres y contener una letra y un numero.');
    }else{
      setError('');
    }
  }

  const handleValidateRepeatPass = (e) => {
    if (!validateRepeatPass(newPass, e.target.value)){
      setError('Las contraseñas no coinciden');
      setSubmitDisabled(true);
    }else{
      setSubmitDisabled(false);
      setError('');
    }
  }

  useEffect(() => {
    if (validatePass(newPass) && validateRepeatPass(newPass, repeatPass)){
      setSubmitDisabled(false);
    } else{
      setSubmitDisabled(true);
    }
  }, [actualPass, newPass, repeatPass])

  return(
    <DashLayout sider={DashRouters} active={'password'}>
      <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
            <h1 className="mb-3">Cambio de contraseña</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="product-detail-card mb-3">
                <Card.Body className="p-3">
                  <Row>
                    <Col className="mx-auto">
                      <Form className="obex-form">
                        <Form.Row className="mb-3">
                          <Form.Label column sm="12" className="obex-form-label">
                          Contraseña Actual
                          </Form.Label>
                          <Col md="12">
                            <InputPassword handleChange={handleActualPass} handleValidate={handleValidatePass} value={actualPass} placeholder="Contraseña Actual"/>
                          </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                          <Form.Label column sm="12" className="obex-form-label">
                            Nueva Contraseña
                          </Form.Label>
                          <Col md="12">
                            <InputPassword handleChange={handleNewPass} handleValidate={handleValidatePass} value={newPass} placeholder="Nueva contraseña"/>
                          </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                          <Form.Label column sm="12" className="obex-form-label">
                            Repite la nueva contraseña
                          </Form.Label>
                          <Col md="12">
                            <Form.Control type="password"  placeholder="Repetir nueva contraseña" value={repeatPass} onChange={handleRepeatPass} onBlur={handleValidateRepeatPass}/>
                          </Col>
                        </Form.Row>
                        <ButtonSubmit loading={loading} submitDisabled={submitDisabled} success={success} error={error} textButton={'Cambiar'} handleSubmit={handleSubmit}/>
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ObexAlert type='error' msg={error}/>
                      <ObexAlert type='success' msg={success}/>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </DashLayout>
  )
}

export default ChangePasswordSetting;