import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { useHistory } from 'react-router-dom';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import SessionService from '../../services/SessionsService';
import { faAddressCard, faBan, faBandAid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ClientsProps = {}



const ClientsContent:FunctionComponent<ClientsProps> = (props) => {

  const history = useHistory();

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState(Array());
  const [stillLoaded, SetStillLoaded] = useState(false);

  const getClients = async () => {
    setLoading(true);
    SetStillLoaded(true);
    try {
      const { status,resultado,message} = await ObexProjectsRequestHandler.get(`/ws/employee_time_control.py/get_employees?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29`);
      console.log(resultado)
      if (!status) throw { message };
      const clientsList = resultado.sort((a, b) => a.id > b.id ? 1 : -1);

      setClients(clientsList);
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
      SetStillLoaded(false);
    };
    SetStillLoaded(false);
  };

  const handleUnblock = async (event, client) => {
  SetStillLoaded(true);


  const resulta = await ObexProjectsRequestHandler.get(`/ws/bloqueos.py/desbloquear?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&telefono=${client.nick}&codigo_pais=ES&id_canal=29`);

  const { status, message } = resulta;
  if (!status) setError(message);
  
  getClients();
  SetStillLoaded(false);
  
  }


  const handleClientDetail = (event, client) => {

    event.preventDefault();
    event.stopPropagation();
    SessionService.ClientConsult = client;
    history.push(`/employees${client.id}/data`);
  }

  useEffect(()  => {
    getClients();
  },[]);


  return(
    <DashLayout sider={DashRouters} active="clients">
      <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
            <h1 className="mb-3">Listado de empleados</h1>
            </Col>
          </Row>

          

          <Row className="mb-3 border-bottom">
            <Col>Nombre</Col>
            <Col>Apellidos</Col>
            <Col>Teléfono</Col>
            <Col>Activo</Col>
            <Col>Bloqueado</Col>
            <Col>Estado actual</Col>
            <Col md="2">Acción</Col>
          </Row>

          {stillLoaded ?
        <Row className="h-100" >
          <Col md="1" className="mx-auto d-flex align-items-center" style={{ marginTop: '15em' }}>
            <Spinner animation="border"/>
          </Col>
        </Row>
        :
        <>

          {clients.map(client => { 
            return (
              <Row className="mb-3">
                <Col className="">
                  <span>{client.nombre}</span>
                </Col>
                <Col className="">
                  <span>{client.apellido1} {client.apellido2}</span>
                </Col>
                <Col className="">
                  <span>{client.nick}</span>
                </Col>
                <Col className="">
                  <span>{client.activo ? "Si" : "No"}</span>
                </Col>
                <Col className="">
                  <span>{client.bloqueo ? "Si" : "No"}</span>
                </Col>
                <Col className="">
                  <span>{client.actual == "out" ? "Salida" : "Entrada"}</span>
                </Col>
                <Col md="2" className="">
                <Button onClick={(event) => handleClientDetail(event, client)} type="submit" className="btn-transparent">
                <FontAwesomeIcon icon={faAddressCard}/> 
                  </Button>

                  <Button disabled={!client.bloqueo} onClick={(event) => handleUnblock(event, client)} type="submit" className="btn-transparent">
                <FontAwesomeIcon icon={faBan}/> 
                  </Button>

                </Col>
              </Row>
            )}
          )}
          
          </>
            }
        </Col>
      </Row>
    </DashLayout>
  )
}

export default ClientsContent;