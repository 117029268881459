import React, { FunctionComponent } from 'react';
import { Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

type buttonSubmitProps = {
  submitDisabled: boolean,
  success: any,
  loading: boolean,
  error: string,
  textButton: string,
  align?: string,
  handleSubmit: (event) => void
}

const ButtonSubmit:FunctionComponent<buttonSubmitProps> = (props) => {

  const { submitDisabled, success, loading, error, align, textButton, handleSubmit } = props;
  
  return (
    <Form.Row className="my-3">
      <Col md="4" className={align == "left" && "mr-auto" || align == "right" && "ml-auto" || "mx-auto"}>
        <Button disabled={submitDisabled || loading} onClick={handleSubmit} type="submit" className="btn-block obex-btn btn-green">
          {textButton} {success && <FontAwesomeIcon icon={faCheck}/>}
          {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
        </Button>
      </Col>
    </Form.Row>
  )
}

export default ButtonSubmit;