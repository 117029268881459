import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers-private.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { useHistory } from 'react-router-dom';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import SessionService from '../../services/SessionsService';
import SendCommunicationModal from './SendCommunication';
import ReadCommunicationModal from './ReadCommunication';
import { message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEnvelope, faEnvelopeOpenText, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { getTomorrowInv } from '../../services/TimeService';
import { faEnvelopeOpen } from '@fortawesome/fontawesome-free-regular';

type ClientsProps = {}



const ClientReceivedCommunications:FunctionComponent<ClientsProps> = (props) => {

  const history = useHistory();

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState(Array());
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [clientToSend, setClientName] = useState('');
  const [clientIDToSend, setClientID] = useState(''); 
  const [messageSent, setMessageSent] = useState('');
  const [messageSubject, setMessageSubject] = useState('');
  const [individual, setIndividual] = useState(true);
  const [grupo, setGrupo] = useState(false);
  const [pagina,setPagina] = useState(1);
  const [paginaIn,setPaginaIn] = useState(1);
  const [paginaGp,setPaginaGp] = useState(1);
  const [masPaginas,setMasPaginas] = useState(false);
  const [stillLoaded, SetStillLoaded] = useState(false);
  const [fecha_consulta, setFechaConsulta] = useState(getTomorrowInv());


  const handleGrupo = (e) =>{
    setPaginaGp(1);
    setPagina(1);
    setGrupo(true);
    setIndividual(false);
    getMessagesGrupo(1);

  }
  const handleIndividual = (e) =>{
    setPaginaIn(1);
    setPagina(1);
    setGrupo(false);
    setIndividual(true)
    getMessagesIndividual(1);

  }

  const getMessagesIndividual = async (paginaconsulta) => {
    setLoading(true);
    SetStillLoaded(true);
    try {
      const { status,mensajes,hay_mas,message} = await ObexProjectsRequestHandler.get(`/ws/notificaciones.py/leer_notificaciones?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&desde=01-01-1990&hasta=${fecha_consulta}&solo_pendientes=False`);
      console.log(fecha_consulta)
      console.log("RESULTADO MESSAGES "+mensajes);
      
      if (hay_mas){
        setMasPaginas(true);
      } else {
        setMasPaginas(false);
      }
      if (!status) throw { message };
      const clientsList = mensajes.sort((a, b) => a.id > b.id ? -1 : 1);
      console.log(pagina)
      console.log(clientsList)
      setClients(clientsList);
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
      SetStillLoaded(false);
    };
    SetStillLoaded(false);
  };

  const getMessagesGrupo = async (paginaconsulta) => {
    setLoading(true);
    try {
      const { status,resultado} = await ObexProjectsRequestHandler.get(`/ws/notificaciones.py/gestor_notificaciones?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&comando=listado_grupo&pagina=${paginaconsulta}`);
      
      

      const resu = resultado['notificaciones_grupo'];
      if (!status) throw { resultado };
      const clientsList = resu.sort((a, b) => a.id > b.id ? 1 : -1);
      console.log(clientsList)
      setClients(clientsList);
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
    };
  };

  const handleNextPage = (event) => {

    if (individual){
      setPaginaIn(pagina+1)
      setPagina(pagina+1);

      getMessagesIndividual(pagina+1);
    }
    if (grupo){
      setPaginaGp(pagina+1)
      setPagina(pagina+1);

      getMessagesGrupo(pagina+1);
    }

  }

  const handlePrevPage = (event) => {

    if (individual){
      setPaginaIn(pagina-1)
      setPagina(pagina-1);

      getMessagesIndividual(pagina-1);
    }
    if (grupo){
      setPaginaGp(pagina-1)
      setPagina(pagina-1);

      getMessagesGrupo(pagina-1);
    }

  }

  const handleEraseMessage = async (event, client) => {
    SetStillLoaded(true);
    event.preventDefault();
    event.stopPropagation();
    


      const result = await ObexProjectsRequestHandler.get(`/ws/notificaciones.py/borrar_uno?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&id_notificacion=${client.id}`);
      
      const { status, message } = result;
      
      if (status){
                //console.log(result);

                getMessagesIndividual(1); 
                setPagina(1);
                setPaginaIn(1);
                }
      else {
        setError(message);
      
        setTimeout(function() { 
          setError('');

          }.bind(this), 2000)

        SetStillLoaded(false);
      }
    

  }



  const handleEditMessage = (event, client) => {
  
    event.preventDefault();
    event.stopPropagation();
    console.log(client.destino.nombre);
    console.log(client.texto);
    console.log(client.asunto);
    setClientName(client.origen.nombre);
    setClientID(client.id);
    setMessageSent(client.asunto);
    setMessageSubject(client.texto)
    setShow(true);
  }

  useEffect(()  => {
    getMessagesIndividual(1);
  },[]);


  return(

      <><ReadCommunicationModal
      show={show}
      handleClose={handleClose}
      nameProject={clientToSend}
      planID={clientIDToSend}
      priceMonthly={messageSent}
      priceYear={messageSubject} />
      
      <DashLayout sider={DashRouters} active="messages">
        <Row>
          <Col md="9" className="mx-auto">
            <Row>
              <Col md="6">
                <h1  className="mb-6">Notificaciones recibidas</h1>
              </Col>
              
            </Row>
            <Row className="mb-3 border-bottom">
              <Col>Fecha</Col>
              <Col>Asunto</Col>
              <Col>Origen</Col>
              <Col md="2">Acciones</Col>
            </Row>
            <Card className="messages"> 

            {stillLoaded ?
            <Row className="h-100" >
              <Col md="1" className="mx-auto d-flex align-items-center" style={{ marginTop: '15em' }}>
                <Spinner animation="border"/>
              </Col>
            </Row>
            :

            
            <>
            {clients.map(client => {
              return (
                <>
                {client.leido ?

                <Row className="mb-3" >
                  <Col className="">
                    <span>{client.fecha}</span>
                  </Col>
                  <Col className="">
                    <span>{client.asunto}</span>
                  </Col>
                  <Col className="">
                    <span>{client.origen.nombre || "Grupo"}</span>
                  </Col>
                  <Col md="2" className="">

                  <Row>
                    <Col md="2">
                    <Button onClick={(event) => handleEditMessage(event, client)} type="submit" className="btn-transparent">
                    <FontAwesomeIcon icon={faEnvelopeOpenText}/>
                    </Button>
                    </Col>  

                    <Col md="2">
                    <Button onClick={(event) => handleEraseMessage(event, client)} type="submit" className="btn-transparent">
                    <FontAwesomeIcon icon={faTrashAlt}/>
                    </Button>
                    </Col> 
                    </Row>

                  </Col>
                  {error.length > 0 && (
                <Alert variant='danger'>
                  {error}
                </Alert>)}




                </Row>
                :


                <Row className="mb-3" style={{color:'black', font:'small-caption', fontSize: 'inherit'}}>
                  <Col className="">
                    <span>{client.fecha}</span>
                  </Col>
                  <Col className="">
                    <span>{client.asunto}</span>
                  </Col>
                  <Col className="">
                    <span>{client.origen.nombre || "Grupo"}</span>
                  </Col>
                  <Col md="2" className="">

                  <Row>
                    <Col md="2">
                    <Button onClick={(event) => handleEditMessage(event, client)} type="submit" className="btn-transparent">
                    <FontAwesomeIcon icon={faEnvelopeOpenText}/>
                    </Button>
                    </Col>  

                    <Col md="2">
                    <Button onClick={(event) => handleEraseMessage(event, client)} type="submit" className="btn-transparent">
                    <FontAwesomeIcon icon={faTrashAlt}/>
                    </Button>
                    </Col> 
                    </Row>

                  </Col>
                  {error.length > 0 && (
                <Alert variant='danger'>
                  {error}
                </Alert>)}




                </Row>


                }
              </>


              );
            }
           
          
            )}
            </>
          }
            </Card>
          </Col>
        </Row>

        <Row>
        <Col md ="6">
        { (pagina>1) &&
        <Button onClick={(event) => handlePrevPage(event)} type="submit" className="obex-btn btn-gray">
                      Pagina {pagina}
        </Button>}
          
        </Col>
        <Col md="6">
        { masPaginas &&
        <Button onClick={(event) => handleNextPage(event)} type="submit" className="obex-btn btn-gray">
                      Página {pagina+1}
        </Button>}
        </Col>
        </Row>

      </DashLayout></>
  )
}

export default ClientReceivedCommunications;