import React, { FunctionComponent } from 'react';
import Logo from '../../../assets/img/logo.png';
import { UserOutlined } from '@ant-design/icons';
import { Navbar, Nav, Row, Col, Image, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterColumn from './FooterColumn';

const contentFooter = [
  {
    titleColumn:'101OBeX',
    links:[
      {
        title:'Home',
        path:'https://www.101obex.com/'
      },
      {
        title:'Plans and Pricing',
        path:'https://developer.101obex.com/plans'
      },
    ]
  },
  {
    titleColumn:'Developers',
    links:[
      {
        title:'Docs',
        path:'https://developer.101obex.com/docs'
      },
      {
        title:'APIs',
        path:'https://developer.101obex.com/apis'
      },
    ]
  },
  {
    titleColumn:'FAQ',
    links:[
      {
        title:'',
        path:'#'
      },
    ]
  },
  {
    titleColumn:'Contact Us',
    links:[
      {
        title:'Sales Support',
        path:'https://www.101obex.com/#comp-jxad0a3m'
      },
      {
        title:'Technical Support',
        path:'https://101obexworkspace.slack.com/'
      },
      {
        title:'Community Support',
        path:'https://101obexworkspace.slack.com/'
      },
    ]
  }
]

const Footer:FunctionComponent<{}> = () => {

  return (
    <Container>
      <Row id="footer-menu" className="mt-3">
        {contentFooter.map(nav => { return (
          <FooterColumn navColumn={nav}/>
        )})}
      </Row>
      <Row>
        <Col className="my-2 copyright">
          <p className="text-center mt-3 mb-0">&copy;2019-2020 101OBeX a WAYNNOVATE Group company</p>
        </Col>
      </Row>
    </Container>
  );

}
export default Footer;