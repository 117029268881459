import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup, Container  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import SessionService from '../../services/SessionsService';
import SignUpResult from './SignUpResult';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import AddTxSelModal from '../Modals/addTxModalSelect';

type ClientsProps = {}

const ClientStatistics:FunctionComponent<ClientsProps> = (props) => {

  const [error, setError] = useState('');

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [success, setSuccess] = useState('');
  const [nombre, setName] = useState('');
  const [apellido1, setApellido1] = useState('');
  const [apellido2, setApellido2] = useState('');
  const [direccion, setDireccion] = useState('');
  const [localidad, setLocalidad] = useState('');
  const [codigopostal, setCodigoPostal] = useState('');
  const [telefono, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [show, setShow] = useState(false);
  const [addingTx, setAddingTx ] = useState(false);
  const [activeEmployees, setActivos] = useState(0);
  const [maxEmployees, setMaximos] = useState(0);
  
  const handleClose = () => {
    
    setName("");
    setApellido1("");
    setApellido2("");
    setLocalidad("");
    setDireccion("");
    setPhone("");
    setEmail("");
    setCodigoPostal("");
    setSubmitDisabled(true);
   
    setShow(false);
  }

  const [stillLoaded , setStillLoaded] = useState(true);


  const alta = async(event) => {
    event.preventDefault();
    
    if (activeEmployees>=maxEmployees) {setAddingTx(true);} else {


    try {

      setLoading(true);
      setStillLoaded(true);
      var cadena_sign="telefono="+telefono+"&nombre="+nombre+"&";
      if (apellido1!="") cadena_sign+="apellido1="+apellido1+"&";
      if (apellido2!="") cadena_sign+="apellido2="+apellido2+"&";
      if (direccion!="") cadena_sign+="domicilio="+direccion+"&";
      if (localidad!="") cadena_sign+="estado="+localidad+"&";
      if (codigopostal!="") cadena_sign+="codigo_postal_residencia_nacional="+codigopostal+"&";
      if (email!="") cadena_sign+="e_mail="+email+"&";
      cadena_sign+="id_canal=29&codigo_pais=ES&perfil=1&id_afiliado="+SessionService.IdUsuario+"&activa=True"

      const resulta = await SessionService.Alta(cadena_sign);
      const {message} = resulta
      if (!resulta["status"]) { 
                                setSuccess('');
                                setError(message)
                                throw(message);

                              }
                              else
                              {
                                setError('')
                                setSuccess('Alta procesada con exito!');
                                setActivos(activeEmployees+1);
                              }
    }
    catch{

      setSuccess('');
    }
  
  

    setLoading(false);
    setStillLoaded(false);
    setShow(true);
  }
  };

  const handleAccountName = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setName(value);
    if (telefono.length==9) setSubmitDisabled(false);
  }
  const handleApellido1 = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setApellido1(value);

  }
  const handleApellido2 = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setApellido2(value);

  }

  const handleDireccion = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setDireccion(value);

  }  
  const handleLocalidad = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setLocalidad(value);

  }  
  const handleCodigoPostal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setCodigoPostal(value);

  }
  const whenClosing = () => {
    setAddingTx(false);
  }

  const handlePhone= (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    try{
      var valor = Number(value)
      var valor2 = valor.toString();
    if (valor2.length<10 && valor2!="NaN")
      { 
        if (valor==0) valor2='';
        setPhone(valor2);
        if (nombre!="" && valor2.length==9) setSubmitDisabled(false);
      }
    }
    finally{

    }
  }
  const handleMail = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setEmail(value);

  };

  const get_AffiliateData = async(): Promise<any> => {
    var result = 100;
    result = (await ObexRequestsHandler.get(`/get_max_employees/${SessionService.IdUsuario}`)).data || 100;
    console.log("RESULT "+result)
    const { status,resultado, message} = await ObexProjectsRequestHandler.get(`/ws/employee_time_control.py/get_employees?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29`);
    console.log(resultado);
    var activos=0;
    resultado.forEach(p => {
      if (p.activo) activos+=1;
    })

    setActivos(activos);
    setMaximos(result);

    if (activos>=result) {
      setAddingTx(true);
      return true;
    }
    return false

  };

  useEffect(()  => {
    get_AffiliateData();
    setStillLoaded(false);
  },[]);


  return(
    <><SignUpResult
      show={show}
      handleClose={handleClose}
      nameProject={nombre}
      planID={telefono}
      priceMonthly={success}
      priceYear={error} />
      
      <DashLayout sider={DashRouters} active={'clients'}>
        <Row>
          <Col md="9" className="mx-auto">
            <Row>
              <Col>
                <h1 className="mb-3">Datos del Empleado</h1>
              </Col>
            </Row>
            {stillLoaded ?
              <Container className="vh-100 d-flex flex-column vw-100">
                <Row className="h-100">
                  <Col md="1" className="mx-auto d-flex align-items-center">
                    <Spinner animation="border" />
                  </Col>
                </Row>
              </Container>
              :
              <><Row>
                <Col>
                  <Card className="product-detail-card mb-3">
                    <Card.Body className="p-3">
                      <Row>
                        <Col className="mx-auto">
                          <Form className="obex-form">
                            <Form.Row className="mb-3">
                              <Form.Label column sm="12" className="obex-form-label">
                                Nombre del Empleado*
                              </Form.Label>
                              <Col md="12">
                                <Form.Control required type="text" value={nombre} placeholder="Nombre" onChange={handleAccountName} />
                              </Col>
                            </Form.Row>

                            <Form.Row className="mb-3">
                              <Form.Label column sm="12" className="obex-form-label">
                                Apellidos del Empleado
                              </Form.Label>
                              <Col md="6">
                                <Form.Control type="text" value={apellido1} placeholder="Apellido Paterno" onChange={handleApellido1} />
                              </Col>
                              <Col md="6">
                                <Form.Control type="text" value={apellido2} placeholder="Apellido Materno" onChange={handleApellido2} />
                              </Col>
                            </Form.Row>


                            <Form.Row className="mb-3">
                              <Form.Label column sm="12" className="obex-form-label">
                                Direccion del Empleado
                              </Form.Label>
                              <Col md="12">
                                <Form.Control type="text" value={direccion} placeholder="Direccion" onChange={handleDireccion} />
                              </Col>
                            </Form.Row>
                            <Form.Row className="mb-3">
                              <Col md="9">
                                <Form.Control type="text" value={localidad} placeholder="Localidad" onChange={handleLocalidad} />
                              </Col>
                              <Col md="3">
                                <Form.Control type="number" value={codigopostal} placeholder="Codigo Postal" onChange={handleCodigoPostal} />
                              </Col>
                            </Form.Row>

                            <Form.Row className="mb-3">
                              <Form.Label column sm="6" className="obex-form-label">
                                Teléfono del Empleado*
                              </Form.Label>
                              <Col md="6">
                                <Form.Control required type="phone" value={telefono} placeholder="Teléfono" onChange={handlePhone} />
                              </Col>
                            </Form.Row>
                            <Form.Row className="mb-3">
                              <Form.Label column sm="6" className="obex-form-label">
                                E-Mail del Empleado
                              </Form.Label>
                              <Col md="6">
                                <Form.Control type="email" value={email} placeholder="e-mail" onChange={handleMail} />
                              </Col>
                            </Form.Row>

                            <Row className="mt-3">
                              <Col md="4" className="ml-auto">
                                <Button type="submit" className="btn-block obex-btn btn-green btn btn-primary" onClick={alta} disabled={submitDisabled}>
                                  Dar de alta {success && <FontAwesomeIcon icon={faCheck} />}
                                  {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                                </Button>
                              </Col>

                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  * Datos necesarios para el alta.
                </Col>

              </Row></>}

          </Col>
        </Row>
        {addingTx && <AddTxSelModal shown={addingTx} onSuccess={whenClosing} onCloseAction={whenClosing} maxMonthLimit={100} actualMonthLimit={5}/>}
      </DashLayout></>
  )
}

export default ClientStatistics;