import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { 
  RootRoute,
  PrivateRoute,
  publicRoutes,
  privateRoutes,
  adminRoutes
} from './configs/appRoutes';
import { withTranslation, Trans } from 'react-i18next';

    

function App() {
  return (
    <Router>
      <Switch>        
        {/* 
          * El bloque de código de abajo se encarga de recorrer una a una las rutas definidas en
          * appRoutes.tsx de manera que cada vez que carguemos una ruta en ese fichero se hará de manera dinámica
          * sin "ensuciar" este ya que es el principal.
        */}
        {publicRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
            render={route.render}
          />
        ))}
        {/* 
          * Este igual pero con las privadas
        */}
        {privateRoutes.map((route, index) => (
          <PrivateRoute
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
            render={route.render}
          />
        ))}
        {adminRoutes.map((route, index) => (
        <RootRoute
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
          render={route.render}
        />
        ))}
      </Switch>
    </Router>
  );
}

export default withTranslation('common')(App); 