import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Container, Row, Col,  Modal, Button, Form, Spinner, Card, Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { prependOnceListener } from 'process';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { parseToCoin } from '../../services/AmountService';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import SessionService from '../../services/SessionsService';
import { message } from 'antd';


type AddonsProps = {
  show: boolean,
  handleClose: any,
  nameProject: string,
  planID: number,
  priceMonthly: number,
  priceYear: number
}

const SendCommunicationModal:FunctionComponent<AddonsProps> = props => {

  const { show, handleClose, nameProject, planID, priceMonthly, priceYear } = props;


  const [pricePlanMontly, setPricePlanMontly] = useState(priceMonthly);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [projecstPlanInfo, setProjecstPlanInfo] = useState([]);
  const [requestsPlanInfo, setRequestsPlanInfo] = useState([]);
  const [selectedProject, setProject] = useState(0);
  const [selectedTx, setTx] = useState(0);
  const [projectPlanPriceMonthly, setProjectPriceMonthly] = useState(0);
  const [projectPlanPriceYear, setProjectPriceYear] = useState(0);
  const [txPlanPriceMonthly, setTxPriceMonthly] = useState(0);
  const [txPlanPriceYear, setTxPriceYear] = useState(0);
  const [txDesc, setTxDesc] = useState('');
  const [projectDesc, setProjectDesc] = useState('');
  const [Message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [subject, setSubject] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const [internalNotity, chageInternalNotify] = useState(true);
  const [mailNotity, chageMailNotify] = useState(false);
  const [smsNotity, chageSMSNotify] = useState(false);
  const [pushNotity, chagePUSHNotify] = useState(false);


  const history = useHistory();


  const changeSubject = (e) => {
    const newSubject = e.target.value;
    setSubject(newSubject);
  }

  const changeMessage = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
  }

  const setInternalNotify = (e) =>{

    chageInternalNotify(!internalNotity);

  }
  const setmailNotify = (e) =>{

    chageMailNotify(!mailNotity);

  }
  const setSMSNotify = (e) =>{

    chageSMSNotify(!smsNotity);

  }
  const setPUSHNotify = (e) =>{

    chagePUSHNotify(!pushNotity);

  }


  const selectProject = (id, priceMonth, priceYear, desc) => {
    setProject(id);
    setProjectDesc(desc);
    setProjectPriceMonthly(priceMonth);
    setProjectPriceYear(priceYear);
  } 

  const selectTx = (id, priceMonth, priceYear, desc) => {
    setTx(id);
    setTxDesc(desc);
    setTxPriceMonthly(priceMonth);
    setTxPriceYear(priceYear);
  }

  const removeProject = (price) => {
    setProject(0);
    setProjectPriceMonthly(0);
  }

  const removeTx = () => {
    setTx(0);
    setTxPriceMonthly(0);
    setTxPriceYear(0);
  }


  const handleSubmit = (event) => {
    const stateToSend = { 
      extraRequestsPlan: selectedTx,
      extraProjectsPlan: selectedProject,
      planID: planID,
      totalPriceMonthly: Number((pricePlanMontly + projectPlanPriceMonthly + txPlanPriceMonthly).toFixed(2)),
      pricePlanMonthly: pricePlanMontly,
      totalPriceYear:  Number((priceYear + projectPlanPriceYear + txPlanPriceYear).toFixed(2)),
      pricePlanYear: priceYear,
      nameProject: nameProject,
      projectPriceMonthly: projectPlanPriceMonthly,
      projectPriceYear: projectPlanPriceYear,
      txPriceMonthly: txPlanPriceMonthly,
      txPriceYear: txPlanPriceYear,
      txDesc: txDesc,
      projectDesc: projectDesc
    }
    console.log('state to senddd ', stateToSend)
    event.preventDefault();
    event.stopPropagation();
    history.push({
      pathname: '/signup',
      state: stateToSend
    })
  };

  const getPlans = async () => {
    try {
      const projectsPlans = (await ObexRequestHandler.get('/extraprojects/plans')).data;
      const requestsPlans = (await ObexRequestHandler.get('/extrarequests/plans')).data;
      // TODO ver si mostrar todos de momento solo el primero
      setProjecstPlanInfo([projectsPlans[0]]);
      setRequestsPlanInfo([requestsPlans[0]]);
    } catch (error) {
      console.error('ERROR FETCHING PLANS ', error);
    }
  }

  const handleSubmitMessage = async () => {
    try {

      setSending(true);
      const result = await ObexProjectsRequestHandler.get(`/ws/notificaciones.py/gestor_notificaciones?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&comando=crear&asunto=${subject}&texto=${Message}&destino=${planID}&mail=${mailNotity}&sms=${smsNotity}&push=${pushNotity}`);
      const {status,message} = result
      if (status){
        setSuccess("El mensaje ha sido enviado con éxito.");
        setMessage('');
        setSubject('');
      }
      console.log(result)
      setSending(false);
      //handleClose();

    } catch (error) {
      console.error('ERROR SENDING MESSAGE ', error);
      setError(error)
    }

    setTimeout(function() { 
      setSuccess('');
      setError('');
      handleClose();
      }.bind(this), 2000)

  }

  const projectsAddons = projecstPlanInfo.map(p => <Row 
      className={'addon addons-border py-3 px-4 mb-4 position-relative' +
      (selectedProject === p.id && ' addons-active')}>
      {selectedProject === p.id && <FontAwesomeIcon icon={faCheck} />}
        <Col md="12" className="addons-title">
          <span>{p.title}</span>
        </Col>
        <Col>
          <Row>
            <Col md="6">
              <span>{p.description}</span>
            </Col>
            <Col className="price-addons">
            <span>{`${p.monthly_price.toFixed(2)}€/mes*`}</span>
            </Col>
            <Col>
              {selectedProject === p.id ? 
                <Button className="btn-block btn-remove-addons" onClick={removeProject}>Remove</Button>:
                <Button className={`btn-block btn-add ${buttonDisabled ? 'btn-ligth-gray': 'btn-add-green'}`} 
                  disabled={buttonDisabled} 
                  onClick={() => selectProject(p.id, p.monthly_price, p.annual_price, p.title)}  
                >
                  Add
                </Button>
              }
            </Col>
          </Row>
        </Col>
      </Row>
      ) 

  const requestsAddons = requestsPlanInfo.map(r => <Row 
      className={'addon addons-border py-3 px-4 mb-4 position-relative' +
      (selectedTx === r.id && ' addons-active')}>
      {selectedTx === r.id && <FontAwesomeIcon icon={faCheck} />}
        <Col md="12" className="addons-title">
          <span>{r.title}</span>
        </Col>
        <Col>
          <Row>
            <Col md="6">
              <span>{r.description}</span>
            </Col>
            <Col className="price-addons">
            <span>{`${r.monthly_price.toFixed(2)}€/mo*`}</span>
            </Col>
            <Col>
              {selectedTx === r.id ? 
                <Button className="btn-block btn-remove-addons" onClick={removeTx}>Remove</Button>:
                <Button className={`btn-block btn-add ${buttonDisabled ? 'btn-ligth-gray': 'btn-add-green'}`} 
                  disabled={buttonDisabled} 
                  onClick={() => selectTx(r.id, r.monthly_price, r.annual_price, r.title)}
                >
                  Add
                </Button>
              }
            </Col>
          </Row>
        </Col>
      </Row>
      )

  useEffect(() => {
    //getPlans();
    //if (nameProject != 'Developer'){
    //  setButtonDisabled(false);
    //}
  }, [])
  
  return(

    

    <Modal dialogClassName="addons-dialog w-75 obex-dialog py-3 obex-dialog"
      show={show} 
      onHide={handleClose}
      animation={false}>
      <Modal.Header closeButton className="border-0 pb-0 pt-4">
        <Modal.Title className=" w-100">
          <Row>
            <Col className="addons-title">
              <span >Envía un mensaje a {nameProject}</span>
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col md="10" className="mx-auto  modal-subtitle">

            <Form className="obex-form">
            <Form.Group></Form.Group>
            Asunto
                  <Form.Group controlId="formBasicName">
                    <Form.Control required placeholder="Indica el asunto" onChange={changeSubject} value={subject}/>
                  </Form.Group>
            Mensaje
            <Form.Group controlId="formBasicMessage">

              <Form.Control required as="textarea" rows={6} placeholder="" onChange={changeMessage} value={Message} />
              </Form.Group>

<Card>
  <div key={`inline-${'checkbox'}`} className="pl-3">
      <Form.Check
        inline
        checked = {internalNotity}
        disabled={false}
        label="Interna"
        name="group1"
        type={'checkbox'}
        id={`inline-${'checkbox'}-1`}
        onClick={setInternalNotify}
      />
      <Form.Check
        inline
        checked={mailNotity}
        disabled={false}
        label="email"
        name="group1"
        type={'checkbox'}
        id={`inline-${'checkbox'}-2`}
        onClick={setmailNotify}
      />
      <Form.Check
        inline
        disabled={false}
        checked={smsNotity}
        label="sms"
        type={'checkbox'}
        id={`inline-${'checkbox'}-3`}
        onClick={setSMSNotify}
      />
        <Form.Check
        inline
        disabled={false}
        checked={pushNotity}
        label="push"
        type={'checkbox'}
        id={`inline-${'checkbox'}-4`}
        onClick={setPUSHNotify}
      />
    </div>
</Card>
            </Form>

            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-4">
        <Container fluid>

          <Row className="mb-2">
            <Col className="text-center">
              
              <Button className="btn-green with-add" onClick={handleSubmitMessage}>
              {sending ? "Enviando" : "Enviar"}
                {sending && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
              </Button>
               
            </Col>
          </Row>

          {error.length > 0 && (
                <Alert variant='danger'>
                  {error}
                </Alert>)}
          {success.length > 0 && (
                <Alert variant='success'>
                  {success}
                </Alert>)}   



        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default SendCommunicationModal;