import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Row, Col, Container, Image, Form, Button, Alert, Spinner, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../assets/img/centuriappazul.png';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ProductDetailCard from './productDetailCard';
import ProductResumeCard from './productResumeCard';
import { useHistory } from 'react-router-dom';
import '../../assets/css/signup.css';
import '../../assets/css/baseLayout.css';
import ObexAlert from '../basicComponents/ObexAlerts';
import { validatePass, validateRepeatPass } from '../../services/validationService';


type SignupProps = {
  extraRequestsPlan: number,
  extraProjectsPlan: number,
  planID: number,
  totalPriceMonthly: number,
  totalPriceYear: number,
  nameProject: string,
  pricePlanMonthly: number,
  pricePlanYear: number,
  projectPriceMonthly: number,
  projectPriceYear: number,
  txPriceMonthly:number,
  txPriceYear:number,
  txDesc:string,
  projectDesc:string,
  newRate: number
}

const SignUp:FunctionComponent<SignupProps> = props => {

  const { 
    extraRequestsPlan,
    extraProjectsPlan,
    planID,
    totalPriceMonthly,
    totalPriceYear,
    nameProject,
    pricePlanMonthly,
    pricePlanYear,
    projectPriceMonthly,
    projectPriceYear,
    txPriceMonthly,
    txPriceYear,
    txDesc,
    projectDesc,
    newRate
  } = props;

  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [affiliateName, setAffiliateName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [annualPayment, setAnnualPayment] = useState(true);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [cvv, setCVV] = useState('');
  const [conditions, setConditions] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {setShowPassword(!showPassword)};
  const handleConditions = () => {setConditions(!conditions)};
  const [ListCountries, setCountriesL] = useState([]);
  const [cargado, setCargado] = useState(false);

  const yearInputRef = useRef(null);
  const cvvInputRef = useRef(null);

  const changeMail = (e) => {
    const newMail = e.target.value;
    setMail(newMail);
  }

  const changePass = (e) => {
    const newPass = e.target.value;
    setPassword(newPass);
  }

  const changeAffiliate = (e) => {
    const newName = e.target.value;
    setAffiliateName(newName);
  }

  
  const changeCardName = (e) => {
    setCardName(e.target.value);
  }

  const changeCardNumber = (e) => {
    var newCardNumber = e.target.value.split(" ").join(""); // remove hyphens
    setCardNumber(newCardNumber);
    if (newCardNumber.length > 0) {
      newCardNumber = newCardNumber.match(new RegExp('.{1,4}', 'g')).join(" ");
    }
    e.target.value = newCardNumber;
  }

  const changeMonth = (e) => {
    const newMonth = e.target.value;
    setMonth(newMonth);
    if (newMonth.toString().length == e.target.maxLength){
      yearInputRef.current.focus();
    }
  }

  const changeYear = (e) => {
    const newYear = e.target.value;
    setYear(newYear);
    if (newYear.toString().length == e.target.maxLength){
      cvvInputRef.current.focus();
    }
  }

  const changeCVV = (e) => {
    const newCVV = e.target.value;
    setCVV(newCVV);
  }

  const handleSwitch = () => {
    setAnnualPayment(!annualPayment);
  }

  const handleValidatePass = (e) => {
    if (!validatePass(e.target.value)){
      setError('The password does not contain more than 8 characters, it includes at least 1 letter and number');
    }else{
      setError('');
    }
  }

  const getCountries = async () => {
    try {
      if (!cargado){
      const result = (await ObexRequestsHandler.get('/countries')).data;
      setCargado(true);
      setCountries(result);
      const countriesList = result.map(c => <option value={c.country_code} selected={locality == c.country_code }>{c.country}</option>);
      setCountriesL(countriesList);
      }
    } catch (error) {
      console.error('ERROR FETCHING COUNTRIES ', error);
    }

  }

  const handleValidateRepeatPass = (e) => {
    if (!validatePass(e.target.value)){
      setError('Passwords do not match');
    }else{
      setError('');
    }
  }
  const [countries, setCountries] = useState([]);
  const [locality, setLocality] = useState('');
  
  
  function routeDashboard() {
    const path = '/dashboard';
    window.location.href=path
  }

  const history = useHistory();

  function routeLogin() {
    history.push({
      pathname: '/verify_signup'
    })
  }

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {

    getCountries();

    if (mail && password && cardNumber && month && year && cvv && conditions && affiliateName){
      setDisabled(false);
    }else{
      setDisabled(true);
    }
    
  }, [mail, password, cardNumber, month, year, cvv, conditions])

  const handleSubmit = async (event) => {

    event.preventDefault();
    event.stopPropagation();
    setError('');// Limpiamos los posibles errores
    setLoading(true);

    const payload = {
      email: mail,
      password: password,
      username: mail,
      subscription: planID,
      annualPayment: annualPayment,
      cardNumber: cardNumber,
      cardMonthExp: month,
      cardYearExp: year,
      cardCvv: cvv,
      cardName: cardName,
      extraProjectsPlan: extraProjectsPlan,
      extraRequestsPlan: extraRequestsPlan,
      countryCode: 'MX',
      ApiKey: 'WcdoRNOUS8jQLs5XWo75yjwzvEZazcSI',
      AffiliateName: affiliateName,
      employees: props.newRate
    }
    const result = await ObexRequestsHandler.post('/signup_affiliate', payload);
    const { success, data, message } = result;
    if (success){
      setSuccess(true);
      routeLogin();
    } else {
      setError(message);
    }
    setLoading(false);
  };

  return(
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col md={7}>
          <Row className="h-100">
            <Col md="8" className="mx-auto my-auto">
              <Row className="h-100">
                <Col xs="6" className="mx-auto mb-4">
                  <Image src={Logo} fluid/>
                </Col>
                <Col md="12" className="text-center">
                  <h1 className="mb-3">Comencemos</h1>
                  <p>Revisa tu plan y crea tu cuenta</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form onSubmit={handleSubmit} className="signup-form obex-form">
                  <Form.Row className="mb-3">
                      <Col>
                        <Form.Control required type="text" placeholder="Nombre de la empresa" onChange={changeAffiliate}/>
                      </Col>
                      {/* <Form.Check type="checkbox" label="Use email address as username" /> */}
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Col>
                        <Form.Control required type="email" placeholder="Dirección de correo" onChange={changeMail}/>
                      </Col>
                      {/* <Form.Check type="checkbox" label="Use email address as username" /> */}
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Col>
                        <InputGroup className="obex-input-group mb-3">
                          <FormControl
                            placeholder="Contraseña"
                            aria-describedby="password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={changePass} onBlur={handleValidatePass}
                          />
                          <InputGroup.Append>
                            <Button onClick={handleShowPassword} className="text-black">
                              {showPassword ? 
                                <FontAwesomeIcon icon={faEyeSlash}/> : 
                                <FontAwesomeIcon icon={faEye}/>}
                                </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <span>Debe contener más de 8 caracteres, incluyendo al menos 1 letra y un número.</span>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Col>
                        <Form.Control required type="text" placeholder="Nombre del titular de la tarjeta" onChange={changeCardName}/>
                      </Col>
                    </Form.Row>
                    <Form.Group>
                      <Form.Row className="info-credit-card">
                        <Col md="12">
                          <Form.Control
                            required
                            type="text"
                            maxLength={19}
                            placeholder="Número de la tarjeta"
                            onChange={changeCardNumber}
                          />
                        </Col>
                      </Form.Row> 
                    </Form.Group>
                    <Form.Group>
                      <Form.Row className="info-credit-card">
                        
                        <Col md="2">
                          <Form.Control required type="text" minLength={2} maxLength={2} placeholder="MM" onChange={changeMonth}/>
                        </Col>
                        <Col md="2">
                          <Form.Control
                            required
                            ref={yearInputRef}
                            type="text"
                            minLength={4}
                            maxLength={4}
                            placeholder="YYYY"
                            onChange={changeYear}
                          />
                        </Col>
                        <Col md="2">
                          <Form.Control 
                            required
                            ref={cvvInputRef}
                            type="text" 
                            minLength={3} 
                            maxLength={4} 
                            placeholder="CVV" 
                            onChange={changeCVV}
                          />
                        </Col>
                        <Col>
                        <Form.Control required as="select" placeholder='Country' custom onChange={null}>
                          <option value="" selected disabled hidden>Elige País</option>
                            {ListCountries}
                        </Form.Control>
                        </Col>


                      </Form.Row>
                    </Form.Group>
                    <Form.Row key="custom-checkbox">
                      <Col md="1" style={{maxWidth:'24px'}}>
                        <div key={'custom-checkbox'}>
                          <Form.Check 
                            custom
                            type="checkbox"
                            id={'custom-checkbox'}
                            label="" 
                            onClick={handleConditions}
                          />
                          
                        </div>
                        
                      </Col>
                      <Col md="11" className='pt-1 mb-2'>
                      Acepto 
                      <a                            
                            target="_blank" rel="noopener noreferrer"
                            href="https://101obex.com/termsconditions"> Los términos del servicio </a>
                      y he leido  
                      <a                           
                            target="_blank" rel="noopener noreferrer"
                            href="https://101obex.com/privacypolicy"> Su política de privacidad.</a>
                          

                        </Col>
                    </Form.Row>
                    <Row>
                      <Col>
                        <ObexAlert type='error' msg={error}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mx-auto">
                        <Form.Row className="text-center">
                          <Button type="submit" className="my-2 obex-btn login btn-block" disabled={disabled}>
                            Continuar
                            {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                          </Button>
                        </Form.Row>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col md={5} className="resume-plan">
          <Row className="h-100">
            <Col md="11" className="mx-auto my-auto">
              <Row className="">
                <Col>
                  <h3 className="title-products">Tus Productos</h3>
                </Col>
              </Row>
              <ProductDetailCard title={'Suscripción'} 
                descrip={nameProject} 
                priceMonthly={pricePlanMonthly*newRate}  
                priceYear={pricePlanYear*newRate} 
                annualPayment={annualPayment}
                letMonths={12}
              />
              {extraProjectsPlan > 0 && 
                <ProductDetailCard title="Extra projects add on" 
                  descrip={projectDesc} 
                  priceMonthly={projectPriceMonthly*newRate} 
                  priceYear={projectPriceYear*newRate} 
                  annualPayment={annualPayment}
                  letMonths={12}
                />
              }
              {extraRequestsPlan > 0 &&
                <ProductDetailCard title="Extra requests add on" 
                  descrip={txDesc} 
                  priceMonthly={txPriceMonthly} 
                  priceYear={txPriceYear} 
                  annualPayment={annualPayment}
                  letMonths={11}
                />
              }
              <ProductResumeCard title="Importe Total" 
                prorated={true} 
                priceMonthly={totalPriceMonthly*newRate} 
                priceYear={totalPriceYear*newRate} 
                annualPayment={annualPayment}
                letMonths={11}
              />
              {(nameProject!='Developer') &&
              <Form className="obex-form">
                <Form.Row>
                  <Col md="6">
                    <Form.Check 
                      checked={annualPayment}
                      type="switch"
                      id="custom-switch"
                      label="Pago Anual"
                      onChange={handleSwitch}
                      className="obex-switch"
                    />
                    </Col>
                    <Col md="6" className="text-right">
                      <span>*Podrian aplicarse impuestos</span>
                    </Col>
                </Form.Row>
              </Form>}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default SignUp;