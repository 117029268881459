

/* export const firstMonthPrice = (totalPrice) => {
  const date = new Date();
  const lastDay = new Date(date.getFullYear(), date.getMonth() +1, 0).getDate();
  const price = (totalPrice / lastDay) * (lastDay - date.getDate() + 1); // Le sumamos uno porque el día actual se paga
  return price.toFixed(2);
} */

export const daysInThisMonth = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1);
  const numberOfDays = new Date(year, month, 0).getDate();
  const today = date.getDate();
  return { today, numberOfDays };
};

export const firstMonthPrice = (price) => {
  const { today, numberOfDays } = daysInThisMonth();
  const percentToPay = (((today - 1) / numberOfDays) * 100 - 100) * -1; // Esto calcula el porcentaje a pagar(a today se le resta uno porque el mismo día en el que se registra si que se paga)
  const proRatePrice = ((price / 100) * percentToPay).toFixed(2);
  return parseFloat(proRatePrice);
}

export const parseToCoin = (amount) => {
  const valueFormat = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(amount);
  return `${valueFormat.slice(0, -1)}EUR`;
}


// TERMINAR

export const parseToNumberFormat = (amount) => {
  const valueFormat = new Intl.NumberFormat('de-DE').format(amount);
  return valueFormat;
}

export const amountToEurPlain = amount => {
  const eur = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount.toString());
  return `${eur.slice(0, -2)}`;
}

// Formate un valor a euro => 4 = € 4,00
export const amountToEur = amount => {
  const eur = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount.toString());
  return `€${eur.slice(0, -2)}`;
}

export const amountToCoin = amount => {
  const coin = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount.toString());
  return coin.slice(0, -2);
}