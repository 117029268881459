import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { useHistory } from 'react-router-dom';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import SessionService from '../../services/SessionsService';
import { faAddressCard, faBan, faBandAid, faEdit, faPlus, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModifyCommunicationModal from './ModifyCommunication';
import AddLocationModal from './AddLoactionModal';
import { parseToNumberFormat } from '../../services/AmountService';
import iconAgregar from '../../assets/img/agregar-dirección2x.png';

type ClientsProps = {}



const Locations:FunctionComponent<ClientsProps> = (props) => {

  const history = useHistory();

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState(Array());
  const [stillLoaded, SetStillLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    getLocations();
    setShow(false);
  }

  const handleOpen = () => setShow(true);

  const getLocations = async () => {
    setLoading(true);
    SetStillLoaded(true);
    try {
      const {status, resultado, message } = await ObexProjectsRequestHandler.get(`/ws/employee_time_control.py/get_locations?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29`);

      if (!status) throw { message };
      const clientsList = resultado.sort((a, b) => a.id > b.id ? 1 : -1);

      setClients(clientsList);
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
      SetStillLoaded(false);
    };
    SetStillLoaded(false);
  };


  const handleEditLocation = async (event, client) => {


  }

  const handleEraseLocation = async (event, client) => {

    SetStillLoaded(true);
    event.preventDefault();
    event.stopPropagation();
    console.log(client.id);
    const {status, message} = await ObexProjectsRequestHandler.get(`/ws/employee_time_control.py/del_location?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&location_id=${client.id}`);
    if (status){
      getLocations();
      }
      else {
      //setError(message);

      setTimeout(function() { 
      //setError('');

      }.bind(this), 2000)

      SetStillLoaded(false);
      }
    SetStillLoaded(false);
  }

  const handleClientDetail = (event, client) => {

    event.preventDefault();
    event.stopPropagation();
    SessionService.ClientConsult = client;
    history.push(`/employees${client.id}/data`);
  }

  useEffect(()  => {
    getLocations();
  },[]);


  return(
    <DashLayout sider={DashRouters} active="locations">

    <AddLocationModal
      show={show}
      handleClose={handleClose}
      nameProject={"clientToSend"}
      planID={"clientIDToSend"}
      priceMonthly={"messageSubject"}
      priceYear={"messageSent"} />


      <Row>
        <Col md="9" className="mx-auto">
          
        <Row>
            <Col md={9}>
              <h1 className="mb-3">Listado de ubicaciones</h1>
              </Col>
              <Col  md={3}>
              <button className="btn-transparent" style={{ float: 'right', transform: 'translate(-15px,50%)'}}><FontAwesomeIcon icon={faPlusCircle} onClick={handleOpen}/> </button>
              </Col>
        </Row>

          

          <Row className="mb-3 border-bottom">
            <Col>Nombre</Col>
            <Col>Dirección</Col>
            <Col>Código Postal</Col>
            <Col>Distancia mínima</Col>
            <Col md="2">Acción</Col>
          </Row>

          {stillLoaded ?
        <Row className="h-100" >
          <Col md="1" className="mx-auto d-flex align-items-center" style={{ marginTop: '15em' }}>
            <Spinner animation="border"/>
          </Col>
        </Row>
        :
        <>

          {clients.map(client => { 
            return (
              <Row className="mb-3">
                <Col className="">
                  { client.central ? <span><h6>{client.name}</h6></span>:<span>{client.name}</span>}
                </Col>
                <Col className="">
                { client.central ? <span><h6>{client.address}</h6></span>:<span>{client.address}</span>}
                </Col>
                <Col className="">
                { client.central ? <span><h6>{client.cp}</h6></span>:<span>{client.cp}</span>}
                </Col>
                <Col className="">
                  { client.central ? <span><h6>{parseToNumberFormat(client.mindist)} m</h6></span>:<span>{parseToNumberFormat(client.mindist)} m</span>}
                </Col>


                <Col md="2">

                <Row>
                <Col md="2">
                { !client.central ?
                <Button onClick={(event) => handleEditLocation(event, client)} type="submit" className="btn-transparent">
                  <FontAwesomeIcon icon={faEdit}/> 
                </Button>
                :
                <></>
          }
                </Col>
                <Col md="2">
                  { !client.central ?
                <Button onClick={(event) => handleEraseLocation(event, client)} type="submit" className="btn-transparent">
                  <FontAwesomeIcon icon={faTrash}/> 
                </Button>
                :
                <></>
          }
                </Col>
                </Row>

                </Col>
              </Row>
            )}
            )}
              <Col  md={3}>
                <button className="btn-transparent-locations" style={{transform: 'translate(-15px,50%)', backgroundImage: `url(${iconAgregar})`, backgroundSize: '1.8em 1.8em', backgroundRepeat: 'no-repeat'    }} onClick={handleOpen}> </button>
              
                <button className="obex-btn btn-green btn btn-primary pl-3" style={{transform: 'translate(0.08em,50%)',}} onClick={handleOpen}>Agregar dirección</button>
              
              </Col>
            </>
            
          }

        </Col>
      </Row>
    </DashLayout>
  )
}

export default Locations;