import RequestHandler from './RequestHandler';
import { url, port } from '../configs/appConfig.json';


class ObexProjectsRequestHandler {
  private static url = `${url}:${port}`;

  public static async get(path: string, params?: object, disableCache: boolean = false): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = {'101ObexApiKey':'N94c7d1H66oBC7y3rRUpik0EVOum9fCl'};
      const result = await RequestHandler.get(url, params, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(get)', error)
    }
  }

  public static async post(path: string, payload?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = {'101ObexApiKey':'N94c7d1H66oBC7y3rRUpik0EVOum9fCl'};
      const result = await RequestHandler.post(url, payload, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(get)', error)
    }
  }

  public static async put(path: string, payload?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = {'101ObexApiKey':'N94c7d1H66oBC7y3rRUpik0EVOum9fCl'};
      const result = await RequestHandler.put(url, payload, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(put)', error)
    }
  }

  public static async patch(path: string, payload?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = {'101ObexApiKey':'N94c7d1H66oBC7y3rRUpik0EVOum9fCl'};
      const result = await RequestHandler.patch(url, payload, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(put)', error)
    }
  }

  public static async delete(path: string, params?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = {'101ObexApiKey':'N94c7d1H66oBC7y3rRUpik0EVOum9fCl'};
      const result = await RequestHandler.delete(url, params, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(delete)', error)
    }
  }

}

export default ObexProjectsRequestHandler;