import RequestHandler from '../handlers/RequestHandler';
import ObexRequestHandler from '../handlers/ObexRequestsHandler';
import ObexProjectsRequestHandler from '../handlers/ObexProjectsRequestsHandler';
import ErrorHandler from '../handlers/ErrorHandler';
import SessionStorageHandler from '../handlers/SessionStorageHandler';
import Cookies from 'universal-cookie/cjs/Cookies';
import { Result } from 'antd';


class SessionService {
  private static secondsTimeout;
  private static checkInactivityInterval;
  private static sinceGetTokenInterval;
  private static secondsSinceGetToken  = 0;
  private static inactivitySecondCounter = 0;
  private static dispatchedInactivity = false;
  public static TipoUsuario = 0;
  public static SessionID = "";
  public static IdUsuario = 0;
  public static NombreCuenta = "";
  public static DatosEmpresa = {};
  public static QR = "https://api.qrserver.com/v1/create-qr-code/?data=HelloWorld&amp;size=500x500";
  public static QRp = "https://api.qrserver.com/v1/create-qr-code/?data=HelloWorld&amp;size=275x275";
  public static Logo = ""
  public static LoginError = ""
  public static ResultadoLogin = {};
  public static ClientConsult = {};
 
  // Evento para cuando se expira la sesión
  private static sessionExpiredEvent = document.createEvent('Event');
  private static inactivityEvent = document.createEvent('Event');

  private static restartInactivitySecondCounter = (e) => {
    if (!SessionService.dispatchedInactivity) SessionService.inactivitySecondCounter = 0;
  }

  public static async Alta(cadena: string):Promise<any> {

    try {
      const result =  await ObexProjectsRequestHandler.get("/ws/cuentas.py/alta?"+cadena.toString());
      return result;
    } catch (error) {
      throw error;
    }

  }

  public static async login(email: string, password: string):Promise<any> {
    try {
      SessionService.dispatchedInactivity = false;

      const result =  await ObexProjectsRequestHandler.get(`/ws/users.py/login?nick=${email}&password=${password}&codigo_pais=ES&id_canal=29`);

      
      const { codigo_perfil,status, id_sesion, id_perfil, id_usuario, id_afiliado_usuario,nombre,error } = result;
      SessionService.ResultadoLogin = result;

      if (!status && error!="sesion_existente") throw { result };
      SessionService.SessionID = id_sesion;
      SessionService.LoginError = error || "";
      SessionService.IdUsuario = id_usuario;
      SessionService.NombreCuenta = nombre;
      SessionService.TipoUsuario = id_perfil;
      if (id_perfil == 6 ){
      const result2 =  await ObexProjectsRequestHandler.get(`/ws/employee_time_control.py/get_enterprise_code?id_usuario=${SessionService.IdUsuario}&codigo_pais=ES&id_sesion=${SessionService.SessionID}&id_canal=29`);

      const resultado  = result2["resultado"]["result"];

      SessionService.DatosEmpresa = resultado;
      SessionService.QR = "https://api.qrserver.com/v1/create-qr-code/?data="+result2["resultado"]["codigo"]+"&amp;size=500x500" 
      SessionService.QRp = "https://api.qrserver.com/v1/create-qr-code/?data="+result2["resultado"]["codigo"]+"&amp;size=260x260" 
      SessionService.Logo = resultado["url_imagen"] || "https://socialimpact.com/wp-content/uploads/2021/03/logo-placeholder-300x210.jpg"
      }

      if (!status && SessionService.LoginError!="sesion_existente") throw { result };
      
      if (SessionService.LoginError!="sesion_existente"){
      //const { secondsTokenExpiration } =  data; // Data es la info del login, el token, si es admin etc.
      const secondsTokenExpiration = 300;
      SessionService.secondsTimeout = secondsTokenExpiration;
      SessionService.sinceGetTokenInterval= setInterval(() => {
        SessionService.secondsSinceGetToken++;
        SessionService.inactivitySecondCounter++;
      }, 1000);
      SessionService.setExpirationCountdown(id_sesion);
      document.addEventListener('click', SessionService.restartInactivitySecondCounter, false);
      document.addEventListener('mouseover', SessionService.restartInactivitySecondCounter, false);
      document.addEventListener('keypress', SessionService.restartInactivitySecondCounter, false);
      SessionService.TipoUsuario = id_perfil;
    }
      SessionService.TipoUsuario = id_perfil;

      return result;
    } catch (error) {
      throw error;
    }
  }

  public static dispatchSessionExpiredEvent():void {
    SessionService.sessionExpiredEvent.initEvent('sessionExpiredEvent', true, true);
    document.dispatchEvent(SessionService.sessionExpiredEvent);
  }

  public static dispatchInactivityEvent():void {
    SessionService.inactivityEvent.initEvent('inactivityEvent', true, true);
    document.dispatchEvent(SessionService.inactivityEvent);
  }

  public static checkInactivity(expiration):void {
    const allowInactivitySeonds = 60;
    if (SessionService.secondsSinceGetToken >= expiration) {
      if (SessionService.inactivitySecondCounter < allowInactivitySeonds) {
        SessionService.renewSession();
      } else {
        if (!SessionService.dispatchedInactivity) {
          SessionService.dispatchInactivityEvent();
          SessionService.dispatchedInactivity = true;
        }
      }
    }
  }

  private static getExpirationDate():Date {
    const sessionExpiration = SessionService.secondsTimeout;
    const date = new Date();
    date.setSeconds(date.getSeconds() + sessionExpiration)
    return date;
  }

  public static async renewSession():Promise<void> {
    try {

      const result =  await ObexProjectsRequestHandler.get(`/ws/users.py/compruebasesion?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29`);
      //const result = await ObexRequestHandler.get('/session/renew');
      //const { success, data, message } = result; // data es el token
      const success = true;
      const message = "";
      const data = "";
      if (!success) throw { message };
      
      const expirationDate = SessionService.getExpirationDate();
      
      SessionService.secondsSinceGetToken = 0;
      SessionService.dispatchedInactivity = false;
    } catch (error) {
      throw error;
    }
  }

  private static setExpirationCountdown(userInfo):void {
    SessionService.SessionID = userInfo;
    const expirationDate = SessionService.getExpirationDate();
    const cookies = new Cookies();
    cookies.set('101Obex', JSON.stringify(userInfo), { path: '/', expires: expirationDate });
    const expiresIn = (SessionService.secondsTimeout  - 30); // Damos 30 segundos de margen
    SessionService.checkInactivityInterval = setInterval(() => SessionService.checkInactivity(expiresIn), 1000);
  }

  public static async logout():Promise<void> {
    try {

      const result =  await ObexProjectsRequestHandler.get(`/ws/users.py/logout?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29`);
      const cookies = new Cookies();
      cookies.remove('101Obex');
      SessionService.SessionID="";
      clearInterval(SessionService.checkInactivityInterval);
      clearInterval(SessionService.sinceGetTokenInterval);
      SessionService.secondsSinceGetToken  = 0;
      SessionService.inactivitySecondCounter = 0;
      document.removeEventListener('click', SessionService.restartInactivitySecondCounter, false);
      document.removeEventListener('mouseover', SessionService.restartInactivitySecondCounter, false);
      document.removeEventListener('keypress', SessionService.restartInactivitySecondCounter, false);
    } catch (error) {
      throw error;
    }
  }

  public static isAdmin():boolean {

    if (SessionService.TipoUsuario == 6){
      return true;
    }
    return false;
  } 
  public static isUser():boolean {
    if (SessionService.TipoUsuario == 1){
      return true;
    }
    return false;
  } 
}

export default SessionService;