import React, { FunctionComponent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Image, Form, Button, Spinner, Alert } from 'react-bootstrap';
import PasswordRecovery from '../Modals/passwordRecovery';
import { useParams } from 'react-router-dom';
import Logo from '../../assets/img/logo_negro.png';
import Logo2 from '../../assets/img/status-of-the-services.png';
import OKSTATUS from '../../assets/img/ok_status.png';
import KOSTATUS from '../../assets/img/ko_status.png';
import WRSTATUS from '../../assets/img/wr_status.png';

import koflag from '../../assets/img/kostatus.png';
import okflag from '../../assets/img/okflag.png';
import dropdown from '../../assets/img/dropdown.png';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import Footer from '../functionalComponents/DashboardLayout/FooterMenu';
import Cookies from 'universal-cookie';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import ClientEdit from '../ClientsComponents/ClientEdit';
import { Line } from 'react-chartjs-2';
import ClientSentCommunications from '../ClientsComponents/clientSentCommunications';

type MainPage = {
  userLabel?: string,
  passLabel?: string,
  rememberCheckboxLabel?: string,
  submitButtonLabel?: string,
  missingUserMsg?: string,
  missingPassMsg?: string,
  loginFailedMsg?: string
}

const MainPage:FunctionComponent<{}> = () => {

  const { token } = useParams();

  const [obex_services, setObexServices] = useState([]);
  const [obex_servers, setObexServers] = useState([]);
  const [obex_multi_customer, setObexMultiCustomers] = useState([]);
  const [obex_unique, setObexUnique] = useState([]);
  const [chartData, setChartData] = useState({});
  const [chartData2, setChartData2] = useState({});
  const [consumerName, setconsumerName] = useState("");
  const [upLimit, setUpLimit] = useState(50);
  const [sending, setSending] = useState(true);
  var datosDatos = [];
  var datosDates = [];
  var datosStates = [];
  var diasDash = []
  var [pulsadorGrafica, setpulsadorGrafica ] = useState([false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,
    false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]);
  var contadorPulsador = 0;

  const options = {
    title:{
      display:false,
    },
    legend:{
      display:false,
      position:'right'
    },
    label:{
      display:false,
      position:'right'
    },

    responsive: true
  };

  const options2 = {
    title:{
      display:false,
    },
    legend:{
      display:false,
      position:'right'
    },
    scales: {
      yAxes: [{
        ticks: {
          min: 0,
          max: 100,
          stepSize: 1
        }
      }]
    },
    responsive: true
  };

  const checkServerHistory = async (token: any) => {
    const result = await ObexProjectsRequestHandler.get(`/ws/status.py/get_multi_status?id=777&codigo_pais=MX`)
    console.log(result)
    setconsumerName(result["name"])
    setUpLimit(result["uplimit"])
    setObexServices(result["obex_services"])
    setObexServers(result["multi_projects"])
    setObexMultiCustomers(result["multi_consumer"])
    setObexUnique(result["unique"])

    result["obex_services"].forEach(element => {
      var datos = []
      var dates = []
      var okstates = []
      element["data"]["60Days"].forEach(elemento =>{
        //dates.push(elemento["date"])
        dates.push("")
        datos.push(elemento["mean_elapsed"])
        okstates.push(elemento["ok_percent"])
      })

      datosDatos = datos
      datosDates = dates
      datosStates = okstates

    });

    var labels = datosDates;

    var dataLine = {
      labels,
      datasets: [
        {
          label: 'Dataset 1',
          data: datosDatos,
          borderColor: 'rgb(0, 171, 135)',
          backgroundColor: 'rgba(255, 99, 132, 0)',
        }]
      }
      setChartData(dataLine)

      var dataLine2 = {
        labels,
        datasets: [
          {
            label: 'Dataset 2',
            data: datosStates,
            borderColor: 'rgb(0, 171, 135)',
            backgroundColor: 'rgba(255, 99, 132, 0)',
          }]
        }
        setChartData2(dataLine2)
        setSending(false);

  }

  useEffect(() => {

    checkServerHistory(token);

  }, [])

  return(
    <Container fluid className="text-center d-flex flex-column min-vh-100" >
      <Row className="justify-content-center" >
        <div className="rounded px-5 pb-4" style={{ width : '800px'}}>
          <Row className="justify-content-center" 
                style={
                  {
                    backgroundColor: "white", 
                    paddingTop:"20px", 
                    paddingBottom: "20px", 
                    paddingLeft: 0, 
                    paddingRight: 0,
                    marginRight: "-60px",
                    marginLeft: "-60px",
                    marginBottom: "3px"
                    }}
                    onClick={() => {
                      console.log("CERRANDO");
                      var temp = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
                        false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
                
                      setpulsadorGrafica(temp);
                        }
                      
                      }
                    >
            <Col >
              <Image src={Logo2} fluid/>
            </Col>
          </Row>

          <Row style={
              {
                color : "white", 
                backgroundColor : "#969593",
                justifyContent: "center",
                paddingTop : "7px",
                paddingBottom: "4px",
                marginBottom: "1em",
                marginRight: "-60px",
                marginLeft: "-60px",
                fontSize: "18px",
                }}>
            STATUS OF THE SERVICES AND INTEGRATIONS FOR {consumerName.toUpperCase()}
          </Row>


        {/****************************************************************************************************
         * 
         * 
         *    101OBeX Infrstructure
         * 
         * 
         */}


{sending && 
<Row style={{minHeight:'50%'}}>
  <Col style={{margin:'auto'}}>
  <Spinner animation="border" style={{ marginLeft: '8px' }} />
</Col>
</Row>
}


{ obex_servers.length>0 ?
          <Row>
            <h3>101OBeX Infrastructure</h3>
          </Row>:
          <></>
          }
          <Row>
            <Col>
            {obex_servers.map(client => { 
              var conta = -1;
              diasDash=[]
              const today = new Date()
              const yesterday = new Date(today)
              const yesterdayy = new Date(today)
              const yesterdayyy = new Date(today)
              const yesterdayyyy = new Date(today)
              const yesterdayyyyy = new Date(today)
              const yesterdayyyyyy = new Date(today)
          
              yesterday.setDate(yesterday.getDate() - 1)
              yesterdayy.setDate(yesterdayy.getDate() - 2)
              yesterdayyy.setDate(yesterdayyy.getDate() - 3)
              yesterdayyyy.setDate(yesterdayyyy.getDate() - 4)
              yesterdayyyyy.setDate(yesterdayyyyy.getDate() - 5)
              yesterdayyyyyy.setDate(yesterdayyyyyy.getDate() - 6)
          
              diasDash.push(new Date().toLocaleDateString('en-US', { weekday: 'long' }));
              diasDash.push(yesterday.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayy.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayyy.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayyyy.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayyyyy.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayyyyyy.toLocaleDateString('en-US', { weekday: 'long' }))
              var lastWeek = []
                    lastWeek.push(client.data["60Days"][59])
                    lastWeek.push(client.data["60Days"][58])
                    lastWeek.push(client.data["60Days"][57])
                    lastWeek.push(client.data["60Days"][56])
                    lastWeek.push(client.data["60Days"][55])
                    lastWeek.push(client.data["60Days"][54])
                    lastWeek.push(client.data["60Days"][53])
              contadorPulsador ++;
              var contat = contadorPulsador;
            return (
              <><><Row className="mb-3" style={{
                display: "block",

                backgroundColor: "white",

                marginRight: "-45px",
                marginLeft: "-45px",

                borderLeftStyle: "solid",
                borderRightStyle: "solid",
                borderTopStyle: "solid",
                borderBottomStyle: "solid",
                borderTopWidth: "1px",
                borderBottomWidth: "1px",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderTopColor: "#454545",
                borderBottomColor: "#454545",
                borderLeftColor: "#454545",
                borderRightColor: "#454545",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}>
                <Row

                  style={{
                    borderBottomStyle: "solid",
                    borderBottomColor: "#454545",
                    marginRight: "0px",
                    marginLeft: "0px",
                    borderBottomWidth: "1px",
                    backgroundColor: "#efefef",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    height: "40px",
                    paddingTop: "10px"
                  }}
                  onClick={() => {
                    var datos = [];
                    var dates = [];
                    var okstates = [];

                    client.data["60Days"].forEach(elemento => {
                      //dates.push(elemento["date"])
                      dates.push("");
                      datos.push(elemento["mean_elapsed"]);
                      okstates.push(elemento["ok_percent"]);
                    });

                    datosDatos = datos;
                    datosDates = dates;
                    datosStates = okstates;



                    var labels = datosDates;

                    var dataLine = {
                      labels,
                      datasets: [
                        {
                          label: 'Dataset 1',
                          data: datosDatos,
                          borderColor: 'rgb(0, 171, 135)',
                          backgroundColor: 'rgba(255, 99, 132, 0)',
                        }
                      ]
                    };
                    setChartData(dataLine);

                    var dataLine2 = {
                      labels,
                      datasets: [
                        {
                          label: 'Dataset 2',
                          data: datosStates,
                          borderColor: 'rgb(0, 171, 135)',
                          backgroundColor: 'rgba(255, 99, 132, 0)',
                        }
                      ]
                    };
                    setChartData2(dataLine2);


                    var temp = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
                      false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];

                      temp[contat] = !pulsadorGrafica[contat];

                    setpulsadorGrafica(temp);

                  } }
                >
                  <Col className="" style={{ textAlign: "start" }}>
                    <span>{client.description.replace("(STAGING)","")}</span>
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    {client.data.Actual["ok_status"] ?
                      <><span>UP</span><Image style={{ width: "18px", marginLeft: "5px", marginBottom: "3px" }} src={okflag} fluid /></>
                      :
                      <><span>DOWN</span><Image style={{ width: "18px", marginLeft: "5px", marginBottom: "3px" }} src={koflag} fluid /></>}
                  </Col>
                   {/*  <Col style={{ maxWidth: "45px", marginLeft: "-20px", marginBottom: "3px" }}><Image src={dropdown} fluid /></Col> */}
                </Row>
                <Row
                  style={{
                    marginRight: "5px",
                    marginLeft: "5px",
                    marginTop: "5px"
                  }}


                >



                  {client.data["60Days"].map(clientes => {
                    return (





                      (clientes.ok_percent < upLimit ?
                        <Col style={{ width: "10px", padding: 0 }}><Image src={KOSTATUS} fluid /></Col> :
                        (
                          clientes.ok_percent == 100 ?
                        <Col style={{ width: "10px", padding: 0 }}><Image src={OKSTATUS} fluid /></Col>:
                        <Col style={{ width: "10px", padding: 0 }}><Image src={WRSTATUS} fluid /></Col>
                        )
                      )
                    );
                  })}


                </Row>
                <Row
                  style={{
                    marginRight: "5px",
                    marginLeft: "5px",
                  }}
                >
                  <Col style={{ textAlign: "start", color: 'lightgray', padding: 0, fontSize: '10px' }}>
                    60 days ago
                  </Col>
                  <Col style={{ textAlign: "center", color: '#00ab87', padding: 0, fontSize: '12px' }}>
                    {client.data["60DaysMean"]["ok_status"]}% UPTIME.
                  </Col>
                  <Col style={{ textAlign: "end", color: 'lightgray', padding: 0, fontSize: '10px' }}>
                    Today
                  </Col>


                </Row>
              </Row>




              <Row><Col><Row style={{ marginLeft: "-30px", width: "387px" }}><Row className="mb-3" style={{
                      display: "block",
                      backgroundColor: "white",

                      borderLeftStyle: "solid",
                      borderRightStyle: "solid",
                      borderTopStyle: "solid",
                      borderBottomStyle: "solid",
                      borderTopWidth: "1px",
                      borderBottomWidth: "1px",
                      borderLeftWidth: "1px",
                      borderRightWidth: "1px",
                      borderTopColor: "#454545",
                      borderBottomColor: "#454545",
                      borderLeftColor: "#454545",
                      borderRightColor: "#454545",
                      borderBottomLeftRadius: "8px",
                      borderBottomRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                      textAlign: "start",
                      color: "#00ab87",
                      width: "100%"
                    }}
                      hidden={!pulsadorGrafica[contat]}
                    ><Row
                      style={{
                        borderBottomStyle: "solid",
                        borderBottomColor: "#454545",
                        marginRight: "0px",
                        marginLeft: "0px",
                        borderBottomWidth: "1px",
                        backgroundColor: "#efefef",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        height: "40px",
                        paddingTop: "10px",
                        color: "black",
                        marginBottom: "15px",
                        paddingLeft: "10px"
                      }}>TOTAL UPTIME FOR LAST 60 DAYS</Row><h3 style={{ textAlign: "center" }}>{client.data["60DaysMean"]["ok_status"]}%</h3></Row></Row>
                      <Row className="mb-3" style={{
                        display: "block",
                        backgroundColor: "white",

                        borderLeftStyle: "solid",
                        borderRightStyle: "solid",
                        borderTopStyle: "solid",
                        borderBottomStyle: "solid",
                        borderTopWidth: "1px",
                        borderBottomWidth: "1px",
                        borderLeftWidth: "1px",
                        borderRightWidth: "1px",
                        borderTopColor: "#454545",
                        borderBottomColor: "#454545",
                        borderLeftColor: "#454545",
                        borderRightColor: "#454545",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                        textAlign: "start",
                        color: "#00ab87",
                        width: "387px",
                        marginLeft: "-45px",
                        height: "200px",
                        overflow: "auto"
                      }}
                        hidden={!pulsadorGrafica[contat]}
                      ><Row
                        style={{
                          borderBottomStyle: "solid",
                          borderBottomColor: "#454545",
                          marginRight: "0px",
                          marginLeft: "0px",
                          borderBottomWidth: "1px",
                          backgroundColor: "#efefef",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          height: "40px",
                          paddingTop: "10px",
                          color: "black",
                          marginBottom: "15px",
                          paddingLeft: "10px"
                        }}>PAST INCIDENTS
                        </Row>
                            {client.messages.map(clientess => {
                            
                            return (
                              <Row style={{marginRight: "0px",marginLeft: "0px",paddingTop: "0px",}}>
                                {clientess["ok_status"] ?
                                <><Col style={{color: "green", textAlign:"start", marginRight: "-45px"}}><Image style={{width: "5px", marginRight:"5px"}} src={OKSTATUS} fluid />{clientess["message"]}</Col><Col style={{color: "black", textAlign:"end", paddingTop:"5px"}}>{clientess["date"]}</Col></>:
                                <><Col style={{color: "red", textAlign:"start", marginRight: "-45px"}}><Image style={{width: "5px", marginRight:"5px"}} src={KOSTATUS} fluid />{clientess["message"]}</Col><Col style={{color: "black", textAlign:"end", paddingTop:"5px"}}>{clientess["date"]}</Col></>
                            }
                                </Row>
                            );
                            
                          })}
                      </Row>

                    </Col><Col><Row style={{ marginLeft: "10px", width: "387px" }}><Row className="mb-3" style={{
                      display: "block",
                      backgroundColor: "white",
                      width: "100%",
                      borderLeftStyle: "solid",
                      borderRightStyle: "solid",
                      borderTopStyle: "solid",
                      borderBottomStyle: "solid",
                      borderTopWidth: "1px",
                      borderBottomWidth: "1px",
                      borderLeftWidth: "1px",
                      borderRightWidth: "1px",
                      borderTopColor: "#454545",
                      borderBottomColor: "#454545",
                      borderLeftColor: "#454545",
                      borderRightColor: "#454545",
                      borderBottomLeftRadius: "8px",
                      borderBottomRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                      textAlign: "start",
                      color: "#00ab87",
                    }}
                      hidden={!pulsadorGrafica[contat]}
                    ><Row
                      style={{
                        borderBottomStyle: "solid",
                        borderBottomColor: "#454545",
                        marginRight: "0px",
                        marginLeft: "0px",
                        borderBottomWidth: "1px",
                        backgroundColor: "#efefef",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        height: "40px",
                        paddingTop: "0px",
                        color: "black",
                      }}><Col style={{ borderRightStyle: "solid", borderRightWidth: "1px", paddingTop: "10px", textAlign: "center" }}>DAY</Col><Col style={{ borderRightStyle: "solid", borderRightWidth: "1px", paddingTop: "10px", textAlign: "center" }}>DATE</Col><Col style={{ paddingTop: "10px", textAlign: "center" }}>UPTIME</Col></Row>

                      {lastWeek.map(clientess => {
                        conta++;
                        return (
                          <Row style={{ marginRight: "0px", marginLeft: "0px", height: "40px", paddingTop: "0px", }}><Col style={{ color: "black", borderRightStyle: "solid", borderRightWidth: "1px", paddingTop: "10px", textAlign: "center" }}>{diasDash[conta]}</Col><Col style={{ color: "black", borderRightStyle: "solid", borderRightWidth: "1px", paddingTop: "10px", textAlign: "center" }}>{clientess["date"]}</Col><Col style={{ color: "black", paddingTop: "10px", textAlign: "center" }}>{clientess["ok_percent"]}%</Col></Row>
                        );

                      })}


                    </Row></Row></Col></Row>






                <Row className="mb-3" style={{
                  display: "block",
                  backgroundColor: "white",
                  marginRight: "-45px",
                  marginLeft: "-45px",
                  borderLeftStyle: "solid",
                  borderRightStyle: "solid",
                  borderTopStyle: "solid",
                  borderBottomStyle: "solid",
                  borderTopWidth: "1px",
                  borderBottomWidth: "1px",
                  borderLeftWidth: "1px",
                  borderRightWidth: "1px",
                  borderTopColor: "#454545",
                  borderBottomColor: "#454545",
                  borderLeftColor: "#454545",
                  borderRightColor: "#454545",
                  borderBottomLeftRadius: "8px",
                  borderBottomRightRadius: "8px",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                  textAlign: "start",
                  color: "#00ab87"
                }}
                  hidden={!pulsadorGrafica[contat]}
                >

                  {pulsadorGrafica[contat] ?
                    <><Row
                      style={{
                        borderBottomStyle: "solid",
                        borderBottomColor: "#454545",
                        marginRight: "0px",
                        marginLeft: "0px",
                        borderBottomWidth: "1px",
                        backgroundColor: "#efefef",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        height: "40px",
                        paddingTop: "10px",
                        color: "black",
                        marginBottom: "15px"
                      }}>

                      <Col className="" style={{ textAlign: "start" }}>
                        <span>System Metrics</span>
                      </Col>

                    </Row><span style={{ paddingLeft: "10px", textAlign: "start" }}>Respond Time</span><Col md="12" className="p-0 pt-3">
                        <Line data={chartData} options={options} height={6} width={40} />
                      </Col><span style={{ paddingLeft: "10px", textAlign: "start" }}>UP Time</span><Col md="12" className="p-0 pt-3">
                        <Line data={chartData2} options={options2} height={6} width={40} />
                      </Col></>
                    :
                    <Row></Row>}</Row></></>
      
                    
      
                  )}
                )}
      
                  </Col>
                  
                </Row>









          {/*
          { obex_servers.length>0 ?
          <Row>
            <h3>101OBeX Servers</h3>
          </Row>:
          <></>
          }
        */}
          <Row>
            <Col>
            {obex_multi_customer.map(client => { 
              var conta = -1;
              diasDash=[]
              const today = new Date()
              const yesterday = new Date(today)
              const yesterdayy = new Date(today)
              const yesterdayyy = new Date(today)
              const yesterdayyyy = new Date(today)
              const yesterdayyyyy = new Date(today)
              const yesterdayyyyyy = new Date(today)
          
              yesterday.setDate(yesterday.getDate() - 1)
              yesterdayy.setDate(yesterdayy.getDate() - 2)
              yesterdayyy.setDate(yesterdayyy.getDate() - 3)
              yesterdayyyy.setDate(yesterdayyyy.getDate() - 4)
              yesterdayyyyy.setDate(yesterdayyyyy.getDate() - 5)
              yesterdayyyyyy.setDate(yesterdayyyyyy.getDate() - 6)
          
              diasDash.push(new Date().toLocaleDateString('en-US', { weekday: 'long' }));
              diasDash.push(yesterday.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayy.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayyy.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayyyy.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayyyyy.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayyyyyy.toLocaleDateString('en-US', { weekday: 'long' }))
              var lastWeek = []
                    lastWeek.push(client.data["60Days"][59])
                    lastWeek.push(client.data["60Days"][58])
                    lastWeek.push(client.data["60Days"][57])
                    lastWeek.push(client.data["60Days"][56])
                    lastWeek.push(client.data["60Days"][55])
                    lastWeek.push(client.data["60Days"][54])
                    lastWeek.push(client.data["60Days"][53])
            contadorPulsador ++;
            var contat = contadorPulsador;
            return (
              <><><Row className="mb-3" style={{
                display: "block",

                backgroundColor: "white",

                marginRight: "-45px",
                marginLeft: "-45px",

                borderLeftStyle: "solid",
                borderRightStyle: "solid",
                borderTopStyle: "solid",
                borderBottomStyle: "solid",
                borderTopWidth: "1px",
                borderBottomWidth: "1px",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderTopColor: "#454545",
                borderBottomColor: "#454545",
                borderLeftColor: "#454545",
                borderRightColor: "#454545",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}>
                <Row
                  style={{
                    borderBottomStyle: "solid",
                    borderBottomColor: "#454545",
                    marginRight: "0px",
                    marginLeft: "0px",
                    borderBottomWidth: "1px",
                    backgroundColor: "#efefef",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    height: "40px",
                    paddingTop: "10px"
                  }}
                  onClick={() => {
                    var datos = [];
                    var dates = [];
                    var okstates = [];

                    client.data["60Days"].forEach(elemento => {
                      //dates.push(elemento["date"])
                      dates.push("");
                      datos.push(elemento["mean_elapsed"]);
                      okstates.push(elemento["ok_percent"]);
                    });

                    datosDatos = datos;
                    datosDates = dates;
                    datosStates = okstates;



                    var labels = datosDates;

                    var dataLine = {
                      labels,
                      datasets: [
                        {
                          label: 'Dataset 1',
                          data: datosDatos,
                          borderColor: 'rgb(0, 171, 135)',
                          backgroundColor: 'rgba(255, 99, 132, 0)',
                        }
                      ]
                    };
                    setChartData(dataLine);

                    var dataLine2 = {
                      labels,
                      datasets: [
                        {
                          label: 'Dataset 2',
                          data: datosStates,
                          borderColor: 'rgb(0, 171, 135)',
                          backgroundColor: 'rgba(255, 99, 132, 0)',
                        }
                      ]
                    };
                    setChartData2(dataLine2);


                    var temp = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
                      false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];

                    temp[contat] = !pulsadorGrafica[contat];
                    setpulsadorGrafica(temp);

                  } }
                >
                  <Col className="" style={{ textAlign: "start" }}>
                    <span>{client.description}</span>
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    {client.data.Actual["ok_status"] ?
                      <><span>UP</span><Image style={{ width: "18px", marginLeft: "5px", marginBottom: "3px" }} src={okflag} fluid /></>
                      :
                      <><span>DOWN</span><Image style={{ width: "18px", marginLeft: "5px", marginBottom: "3px" }} src={koflag} fluid /></>}
                  </Col>
                   {/*  <Col style={{ maxWidth: "45px", marginLeft: "-20px", marginBottom: "3px" }}><Image src={dropdown} fluid /></Col> */}
                </Row>
                <Row
                  style={{
                    marginRight: "5px",
                    marginLeft: "5px",
                    marginTop: "5px"
                  }}
                >

                  {client.data["60Days"].map(clientes => {
                    return (
                      (clientes.ok_percent < upLimit ?
                        <Col style={{ width: "10px", padding: 0 }}><Image src={KOSTATUS} fluid /></Col> :
                        (
                          clientes.ok_percent == 100 ?
                        <Col style={{ width: "10px", padding: 0 }}><Image src={OKSTATUS} fluid /></Col>:
                        <Col style={{ width: "10px", padding: 0 }}><Image src={WRSTATUS} fluid /></Col>
                        )
                      )
                    );
                  })}


                </Row>
                <Row
                  style={{
                    marginRight: "5px",
                    marginLeft: "5px",
                  }}

                >
                  <Col style={{ textAlign: "start", color: 'lightgray', padding: 0, fontSize: '10px' }}>
                    60 days ago
                  </Col>
                  <Col style={{ textAlign: "center", color: '#00ab87', padding: 0, fontSize: '12px' }}>
                    {client.data["60DaysMean"]["ok_status"]}% UPTIME.
                  </Col>
                  <Col style={{ textAlign: "end", color: 'lightgray', padding: 0, fontSize: '10px' }}>
                    Today
                  </Col>
                </Row>
              </Row>
              
              
              <Row><Col><Row style={{ marginLeft: "-30px", width: "387px" }}><Row className="mb-3" style={{
                      display: "block",
                      backgroundColor: "white",

                      borderLeftStyle: "solid",
                      borderRightStyle: "solid",
                      borderTopStyle: "solid",
                      borderBottomStyle: "solid",
                      borderTopWidth: "1px",
                      borderBottomWidth: "1px",
                      borderLeftWidth: "1px",
                      borderRightWidth: "1px",
                      borderTopColor: "#454545",
                      borderBottomColor: "#454545",
                      borderLeftColor: "#454545",
                      borderRightColor: "#454545",
                      borderBottomLeftRadius: "8px",
                      borderBottomRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                      textAlign: "start",
                      color: "#00ab87",
                      width: "100%"
                    }}
                      hidden={!pulsadorGrafica[contat]}
                    ><Row
                      style={{
                        borderBottomStyle: "solid",
                        borderBottomColor: "#454545",
                        marginRight: "0px",
                        marginLeft: "0px",
                        borderBottomWidth: "1px",
                        backgroundColor: "#efefef",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        height: "40px",
                        paddingTop: "10px",
                        color: "black",
                        marginBottom: "15px",
                        paddingLeft: "10px"
                      }}>TOTAL UPTIME FOR LAST 60 DAYS</Row><h3 style={{ textAlign: "center" }}>{client.data["60DaysMean"]["ok_status"]}%</h3></Row></Row>
                      <Row className="mb-3" style={{
                        display: "block",
                        backgroundColor: "white",

                        borderLeftStyle: "solid",
                        borderRightStyle: "solid",
                        borderTopStyle: "solid",
                        borderBottomStyle: "solid",
                        borderTopWidth: "1px",
                        borderBottomWidth: "1px",
                        borderLeftWidth: "1px",
                        borderRightWidth: "1px",
                        borderTopColor: "#454545",
                        borderBottomColor: "#454545",
                        borderLeftColor: "#454545",
                        borderRightColor: "#454545",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                        textAlign: "start",
                        color: "#00ab87",
                        width: "387px",
                        marginLeft: "-45px",
                        height: "200px",
                        overflow: "auto"
                      }}
                        hidden={!pulsadorGrafica[contat]}
                      ><Row
                        style={{
                          borderBottomStyle: "solid",
                          borderBottomColor: "#454545",
                          marginRight: "0px",
                          marginLeft: "0px",
                          borderBottomWidth: "1px",
                          backgroundColor: "#efefef",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          height: "40px",
                          paddingTop: "10px",
                          color: "black",
                          marginBottom: "15px",
                          paddingLeft: "10px"
                        }}>PAST INCIDENTS
                        </Row>
                        {client.messages.map(clientess => {
                              
                              return (
                                <Row style={{marginRight: "0px",marginLeft: "0px",paddingTop: "0px",}}>
                                  {clientess["ok_status"] ?
                                  <><Col style={{color: "green", textAlign:"start", marginRight:"-45px"}}><Image style={{width: "5px", marginRight:"5px"}} src={OKSTATUS} fluid />{clientess["message"]}</Col><Col style={{color: "black", textAlign:"end", paddingTop:"5px"}}>{clientess["date"]}</Col></>:
                                  <><Col style={{color: "red", textAlign:"start", marginRight:"-45px"}}><Image style={{width: "5px", marginRight:"5px"}} src={KOSTATUS} fluid />{clientess["message"]}</Col><Col style={{color: "black", textAlign:"end", paddingTop:"5px"}}>{clientess["date"]}</Col></>
                              }
                                  </Row>
                              );
                              
                            })}
                        </Row>

                    </Col><Col><Row style={{ marginLeft: "10px", width: "387px" }}><Row className="mb-3" style={{
                      display: "block",
                      backgroundColor: "white",
                      width: "100%",
                      borderLeftStyle: "solid",
                      borderRightStyle: "solid",
                      borderTopStyle: "solid",
                      borderBottomStyle: "solid",
                      borderTopWidth: "1px",
                      borderBottomWidth: "1px",
                      borderLeftWidth: "1px",
                      borderRightWidth: "1px",
                      borderTopColor: "#454545",
                      borderBottomColor: "#454545",
                      borderLeftColor: "#454545",
                      borderRightColor: "#454545",
                      borderBottomLeftRadius: "8px",
                      borderBottomRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                      textAlign: "start",
                      color: "#00ab87",
                    }}
                      hidden={!pulsadorGrafica[contat]}
                    ><Row
                      style={{
                        borderBottomStyle: "solid",
                        borderBottomColor: "#454545",
                        marginRight: "0px",
                        marginLeft: "0px",
                        borderBottomWidth: "1px",
                        backgroundColor: "#efefef",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        height: "40px",
                        paddingTop: "0px",
                        color: "black",
                      }}><Col style={{ borderRightStyle: "solid", borderRightWidth: "1px", paddingTop: "10px", textAlign: "center" }}>DAY</Col><Col style={{ borderRightStyle: "solid", borderRightWidth: "1px", paddingTop: "10px", textAlign: "center" }}>DATE</Col><Col style={{ paddingTop: "10px", textAlign: "center" }}>UPTIME</Col></Row>

                      {lastWeek.map(clientess => {
                        conta++;
                        return (
                          <Row style={{ marginRight: "0px", marginLeft: "0px", height: "40px", paddingTop: "0px", }}><Col style={{ color: "black", borderRightStyle: "solid", borderRightWidth: "1px", paddingTop: "10px", textAlign: "center" }}>{diasDash[conta]}</Col><Col style={{ color: "black", borderRightStyle: "solid", borderRightWidth: "1px", paddingTop: "10px", textAlign: "center" }}>{clientess["date"]}</Col><Col style={{ color: "black", paddingTop: "10px", textAlign: "center" }}>{clientess["ok_percent"]}%</Col></Row>
                        );

                      })}


                    </Row></Row></Col></Row>

              <Row className="mb-3" style={{
                display: "block",
                backgroundColor: "white",
                marginRight: "-45px",
                marginLeft: "-45px",
                borderLeftStyle: "solid",
                borderRightStyle: "solid",
                borderTopStyle: "solid",
                borderBottomStyle: "solid",
                borderTopWidth: "1px",
                borderBottomWidth: "1px",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderTopColor: "#454545",
                borderBottomColor: "#454545",
                borderLeftColor: "#454545",
                borderRightColor: "#454545",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                textAlign: "start",
                color: "#00ab87"
              }}
                hidden={!pulsadorGrafica[contat]}>

                  {pulsadorGrafica[contat] ?
                    <><Row
                      style={{
                        borderBottomStyle: "solid",
                        borderBottomColor: "#454545",
                        marginRight: "0px",
                        marginLeft: "0px",
                        borderBottomWidth: "1px",
                        backgroundColor: "#efefef",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        height: "40px",
                        paddingTop: "10px",
                        color: "black",
                        marginBottom: "15px"
                      }}>

                      <Col className="" style={{ textAlign: "start" }}>
                        <span>System Metrics</span>
                      </Col>

                    </Row><span style={{ paddingLeft: "10px", textAlign: "start" }}>Respond Time</span><Col md="12" className="p-0 pt-3">
                        <Line data={chartData} options={options} height={6} width={40} />
                      </Col><span style={{ paddingLeft: "10px", textAlign: "start" }}>UP Time</span><Col md="12" className="p-0 pt-3">
                        <Line data={chartData2} options={options2} height={6} width={40} />
                      </Col></>
                    :
                    <Row></Row>}</Row></></>

                  )}
                )}
      
                  </Col>
                  
                </Row>



         {/****************************************************************************************************
          * 
          * 
          * 
          *  101OBeX Services
          * 
          * 
          */}


          { obex_services.length>0 ?
          <Row>
            <h3>101OBeX Services</h3>
          </Row>:
          <></>
          }
          <Row
          

          
          >
            <Col>
            {obex_services.map(client => { 
              var conta = -1;
              diasDash=[]
              const today = new Date()
              const yesterday = new Date(today)
              const yesterdayy = new Date(today)
              const yesterdayyy = new Date(today)
              const yesterdayyyy = new Date(today)
              const yesterdayyyyy = new Date(today)
              const yesterdayyyyyy = new Date(today)
          
              yesterday.setDate(yesterday.getDate() - 1)
              yesterdayy.setDate(yesterdayy.getDate() - 2)
              yesterdayyy.setDate(yesterdayyy.getDate() - 3)
              yesterdayyyy.setDate(yesterdayyyy.getDate() - 4)
              yesterdayyyyy.setDate(yesterdayyyyy.getDate() - 5)
              yesterdayyyyyy.setDate(yesterdayyyyyy.getDate() - 6)
          
              diasDash.push(new Date().toLocaleDateString('en-US', { weekday: 'long' }));
              diasDash.push(yesterday.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayy.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayyy.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayyyy.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayyyyy.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayyyyyy.toLocaleDateString('en-US', { weekday: 'long' }))
              var lastWeek = []
                    lastWeek.push(client.data["60Days"][59])
                    lastWeek.push(client.data["60Days"][58])
                    lastWeek.push(client.data["60Days"][57])
                    lastWeek.push(client.data["60Days"][56])
                    lastWeek.push(client.data["60Days"][55])
                    lastWeek.push(client.data["60Days"][54])
                    lastWeek.push(client.data["60Days"][53])
              contadorPulsador ++;
              var contat = contadorPulsador;
            return (
              <><><Row className="mb-3" style={{
                display: "block",


                backgroundColor: "white",

                marginRight: "-45px",
                marginLeft: "-45px",

                borderLeftStyle: "solid",
                borderRightStyle: "solid",
                borderTopStyle: "solid",
                borderBottomStyle: "solid",
                borderTopWidth: "1px",
                borderBottomWidth: "1px",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderTopColor: "#454545",
                borderBottomColor: "#454545",
                borderLeftColor: "#454545",
                borderRightColor: "#454545",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}>
                <Row
                  style={{
                    borderBottomStyle: "solid",
                    borderBottomColor: "#454545",
                    marginRight: "0px",
                    marginLeft: "0px",
                    borderBottomWidth: "1px",
                    backgroundColor: "#efefef",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    height: "40px",
                    paddingTop: "10px"
                  }}
                  onClick={() => {
                    var datos = [];
                    var dates = [];
                    var okstates = [];

                    client.data["60Days"].forEach(elemento => {
                      //dates.push(elemento["date"])
                      dates.push("");
                      datos.push(elemento["mean_elapsed"]);
                      okstates.push(elemento["ok_percent"]);
                    });

                    datosDatos = datos;
                    datosDates = dates;
                    datosStates = okstates;



                    var labels = datosDates;

                    var dataLine = {
                      labels,
                      datasets: [
                        {
                          label: 'Dataset 1',
                          data: datosDatos,
                          borderColor: 'rgb(0, 171, 135)',
                          backgroundColor: 'rgba(255, 99, 132, 0)',
                        }
                      ]
                    };
                    setChartData(dataLine);

                    var dataLine2 = {
                      labels,
                      datasets: [
                        {
                          label: 'Dataset 2',
                          data: datosStates,
                          borderColor: 'rgb(0, 171, 135)',
                          backgroundColor: 'rgba(255, 99, 132, 0)',
                        }
                      ]
                    };
                    setChartData2(dataLine2);


                    var temp = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
                      false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];

                    temp[contat] = !pulsadorGrafica[contat];
                    setpulsadorGrafica(temp);


                    
                    

                  } }
                >
                  <Col className="" style={{ textAlign: "start" }}>
                    <span>{client.description}</span>
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    {client.data.Actual["ok_status"] ?
                      <><span>UP</span><Image style={{ width: "18px", marginLeft: "5px", marginBottom: "3px" }} src={okflag} fluid /></>
                      :
                      <><span>DOWN</span><Image style={{ width: "18px", marginLeft: "5px", marginBottom: "3px" }} src={koflag} fluid /></>}
                  </Col>
                {/*  <Col style={{ maxWidth: "45px", marginLeft: "-20px", marginBottom: "3px" }}><Image src={dropdown} fluid /></Col> */}
                </Row>
                <Row style={{
                  marginRight: "5px",
                  marginLeft: "5px",
                  marginTop: "5px"
                }}>

                  {client.data["60Days"].map(clientes => {

                    return (
                      (clientes.ok_percent < upLimit ?
                        <Col style={{ width: "10px", padding: 0 }}><Image src={KOSTATUS} fluid /></Col> :
                        (
                          clientes.ok_percent == 100 ?
                        <Col style={{ width: "10px", padding: 0 }}><Image src={OKSTATUS} fluid /></Col>:
                        <Col style={{ width: "10px", padding: 0 }}><Image src={WRSTATUS} fluid /></Col>
                        )
                      )
                    );
                  })}


                </Row>
                <Row
                  style={{
                    marginRight: "5px",
                    marginLeft: "5px",
                  }}
                >
                  <Col style={{ textAlign: "start", color: 'lightgray', padding: 0, fontSize: '10px' }}>
                    60 days ago
                  </Col>
                  <Col style={{ textAlign: "center", color: '#00ab87', padding: 0, fontSize: '12px' }}>
                    {client.data["60DaysMean"]["ok_status"]}% UPTIME.
                  </Col>
                  <Col style={{ textAlign: "end", color: 'lightgray', padding: 0, fontSize: '10px' }}>
                    Today
                  </Col>
                </Row>
              </Row>
              
              
              
              <Row><Col><Row style={{marginLeft: "-30px", width: "387px"}}><Row className="mb-3" style={{
                display: "block",
                backgroundColor: "white",

                borderLeftStyle: "solid",
                borderRightStyle: "solid",
                borderTopStyle: "solid",
                borderBottomStyle: "solid",
                borderTopWidth: "1px",
                borderBottomWidth: "1px",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderTopColor: "#454545",
                borderBottomColor: "#454545",
                borderLeftColor: "#454545",
                borderRightColor: "#454545",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                textAlign: "start",
                color: "#00ab87",
                width: "100%"
              }}
                hidden={!pulsadorGrafica[contat]}
              ><Row
              style={{
                borderBottomStyle: "solid",
                borderBottomColor: "#454545",
                marginRight: "0px",
                marginLeft: "0px",
                borderBottomWidth: "1px",
                backgroundColor: "#efefef",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                height: "40px",
                paddingTop: "10px",
                color: "black",
                marginBottom: "15px",
                paddingLeft:"10px"
              }}>TOTAL UPTIME FOR LAST 60 DAYS</Row><h3 style={{textAlign: "center"}}>{client.data["60DaysMean"]["ok_status"]}%</h3></Row></Row>
              <Row className="mb-3" style={{
                display: "block",
                backgroundColor: "white",

                borderLeftStyle: "solid",
                borderRightStyle: "solid",
                borderTopStyle: "solid",
                borderBottomStyle: "solid",
                borderTopWidth: "1px",
                borderBottomWidth: "1px",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderTopColor: "#454545",
                borderBottomColor: "#454545",
                borderLeftColor: "#454545",
                borderRightColor: "#454545",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                textAlign: "start",
                color: "#00ab87",
                width: "387px",
                marginLeft: "-45px",
                height:"200px",
                overflow: "auto"
                
              }}
                hidden={!pulsadorGrafica[contat]}
              ><Row
              style={{
                borderBottomStyle: "solid",
                borderBottomColor: "#454545",
                marginRight: "0px",
                marginLeft: "0px",
                borderBottomWidth: "1px",
                backgroundColor: "#efefef",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                height: "40px",
                paddingTop: "10px",
                color: "black",
                marginBottom: "15px",
                paddingLeft:"10px"
              }}>PAST INCIDENTS
              
              </Row>
              {client.messages.map(clientess => {
                    
                    return (
                      <Row style={{marginRight: "0px",marginLeft: "0px",paddingTop: "0px",}}>
                        {clientess["ok_status"] ?
                        <><Col style={{color: "green", textAlign:"start", marginRight:"-45px"}}><Image style={{width: "5px", marginRight:"5px"}} src={OKSTATUS} fluid />{clientess["message"]}</Col><Col style={{color: "black", textAlign:"end", paddingTop:"5px"}}>{clientess["date"]}</Col></>:
                        <><Col style={{color: "red", textAlign:"start", marginRight:"-45px"}}><Image style={{width: "5px", marginRight:"5px"}} src={KOSTATUS} fluid />{clientess["message"]}</Col><Col style={{color: "black", textAlign:"end", paddingTop:"5px"}}>{clientess["date"]}</Col></>
                    }
                        </Row>
                    );
                    
                  })}
              </Row>
              </Col><Col><Row style={{marginLeft: "10px", width: "387px"}}><Row className="mb-3" style={{
                display: "block",
                backgroundColor: "white",
                width: "100%",
                borderLeftStyle: "solid",
                borderRightStyle: "solid",
                borderTopStyle: "solid",
                borderBottomStyle: "solid",
                borderTopWidth: "1px",
                borderBottomWidth: "1px",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderTopColor: "#454545",
                borderBottomColor: "#454545",
                borderLeftColor: "#454545",
                borderRightColor: "#454545",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                textAlign: "start",
                color: "#00ab87",
              }}
                hidden={!pulsadorGrafica[contat]}
              ><Row
              style={{
                borderBottomStyle: "solid",
                borderBottomColor: "#454545",
                marginRight: "0px",
                marginLeft: "0px",
                borderBottomWidth: "1px",
                backgroundColor: "#efefef",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                height: "40px",
                paddingTop: "0px",
                color: "black",

              }}><Col style={{borderRightStyle: "solid", borderRightWidth: "1px", paddingTop: "10px", textAlign:"center"}}>DAY</Col><Col style={{borderRightStyle: "solid", borderRightWidth: "1px", paddingTop: "10px", textAlign:"center"}}>DATE</Col><Col style={{paddingTop: "10px", textAlign:"center"}}>UPTIME</Col></Row>
              
              {lastWeek.map(clientess => {
                    conta++;
                    return (
                      <Row style={{marginRight: "0px",marginLeft: "0px",height: "40px",paddingTop: "0px",}}><Col style={{color: "black",borderRightStyle: "solid", borderRightWidth: "1px", paddingTop: "10px", textAlign:"center"}}>{diasDash[conta]}</Col><Col style={{color: "black",borderRightStyle: "solid", borderRightWidth: "1px", paddingTop: "10px", textAlign:"center"}}>{clientess["date"]}</Col><Col style={{color: "black",paddingTop: "10px", textAlign:"center"}}>{clientess["ok_percent"]}%</Col></Row>
                    );
                    
                  })}
              
              
              </Row></Row></Col></Row>
              
              
              
              <Row className="mb-3" style={{
                display: "block",
                backgroundColor: "white",
                marginRight: "-45px",
                marginLeft: "-45px",
                borderLeftStyle: "solid",
                borderRightStyle: "solid",
                borderTopStyle: "solid",
                borderBottomStyle: "solid",
                borderTopWidth: "1px",
                borderBottomWidth: "1px",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderTopColor: "#454545",
                borderBottomColor: "#454545",
                borderLeftColor: "#454545",
                borderRightColor: "#454545",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                textAlign: "start",
                color: "#00ab87"
              }}
                hidden={!pulsadorGrafica[contat]}
              >

                  {pulsadorGrafica[contat] ?
                    <><><Row
                      style={{
                        borderBottomStyle: "solid",
                        borderBottomColor: "#454545",
                        marginRight: "0px",
                        marginLeft: "0px",
                        borderBottomWidth: "1px",
                        backgroundColor: "#efefef",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        height: "40px",
                        paddingTop: "10px",
                        color: "black",
                        marginBottom: "15px"
                      }}>

                      <Col className="" style={{ textAlign: "start" }}>
                        <span>System Metrics</span>
                      </Col>

                    </Row><span style={{ paddingLeft: "10px", textAlign: "start" }}>Respond Time</span><Col md="12" className="p-0 pt-3">
                        <Line data={chartData} options={options} height={6} width={40} />
                      </Col><span style={{ paddingLeft: "10px", textAlign: "start" }}>UP Time</span><Col md="12" className="p-0 pt-3">
                        <Line data={chartData2} options={options2} height={6} width={40} />
                      </Col></></>

                    :
                    <Row></Row>}
                </Row></></>

              

            )}
          )}

            </Col>
            
          </Row>



{/* 

          <Row className="mb-3" style={{
              display: "block",
              backgroundColor : "white",
              marginRight: "-45px",
              marginLeft: "-45px",
              borderLeftStyle:"solid",
              borderRightStyle:"solid",
              borderTopStyle:"solid",
              borderBottomStyle:"solid",
              borderTopWidth:"1px",
              borderBottomWidth:"1px",
              borderLeftWidth:"1px",
              borderRightWidth:"1px",
              borderTopColor:"#454545",
              borderBottomColor:"#454545",
              borderLeftColor:"#454545",
              borderRightColor:"#454545",
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              textAlign: "start",
              color: "#00ab87"
            }}>
            

            <Row 
                style={{
                  borderBottomStyle:"solid",
                  borderBottomColor:"#454545",
                  marginRight: "0px",
                  marginLeft: "0px",
                  borderBottomWidth:"1px",
                  backgroundColor: "#efefef",
                  height: "40px",
                  paddingTop: "10px",
                  color: "black",
                  marginBottom: "15px"
              }}>

                <Col className="" style={{textAlign: "start"}}>
                  <span>System Metrics</span>
                </Col>

              </Row>
            <span style={{paddingLeft: "10px", textAlign: "start"}}>Respond Time</span>
            <Col md="12" className="p-0 pt-3">
              <Line data={chartData} options={options} height={6} width={40}/>
            </Col>
            <span style={{paddingLeft: "10px", textAlign: "start"}}>UP Time</span>
            <Col md="12" className="p-0 pt-3">
              <Line data={chartData2} options={options2} height={6} width={40}/>
            </Col>


        </Row>


 */}










          { obex_unique.length>0 ?
          <Row>
            <h3>101OBeX Integrations</h3>
          </Row>:
          <></>
          }
          <Row
          
          
          >
            <Col>
            {obex_unique.map(client => { 
              var conta = -1;
              diasDash=[]
              const today = new Date()
              const yesterday = new Date(today)
              const yesterdayy = new Date(today)
              const yesterdayyy = new Date(today)
              const yesterdayyyy = new Date(today)
              const yesterdayyyyy = new Date(today)
              const yesterdayyyyyy = new Date(today)
          
              yesterday.setDate(yesterday.getDate() - 1)
              yesterdayy.setDate(yesterdayy.getDate() - 2)
              yesterdayyy.setDate(yesterdayyy.getDate() - 3)
              yesterdayyyy.setDate(yesterdayyyy.getDate() - 4)
              yesterdayyyyy.setDate(yesterdayyyyy.getDate() - 5)
              yesterdayyyyyy.setDate(yesterdayyyyyy.getDate() - 6)
          
              diasDash.push(new Date().toLocaleDateString('en-US', { weekday: 'long' }));
              diasDash.push(yesterday.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayy.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayyy.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayyyy.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayyyyy.toLocaleDateString('en-US', { weekday: 'long' }))
              diasDash.push(yesterdayyyyyy.toLocaleDateString('en-US', { weekday: 'long' }))
              var lastWeek = []
                    lastWeek.push(client.data["60Days"][59])
                    lastWeek.push(client.data["60Days"][58])
                    lastWeek.push(client.data["60Days"][57])
                    lastWeek.push(client.data["60Days"][56])
                    lastWeek.push(client.data["60Days"][55])
                    lastWeek.push(client.data["60Days"][54])
                    lastWeek.push(client.data["60Days"][53])
              contadorPulsador ++;
              var contat = contadorPulsador;
            return (
              <><><Row className="mb-3" style={{
                display: "block",
                backgroundColor: "white",

                marginRight: "-45px",
                marginLeft: "-45px",

                borderLeftStyle: "solid",
                borderRightStyle: "solid",
                borderTopStyle: "solid",
                borderBottomStyle: "solid",
                borderTopWidth: "1px",
                borderBottomWidth: "1px",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderTopColor: "#454545",
                borderBottomColor: "#454545",
                borderLeftColor: "#454545",
                borderRightColor: "#454545",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}>
                <Row
                  style={{
                    borderBottomStyle: "solid",
                    borderBottomColor: "#454545",
                    marginRight: "0px",
                    marginLeft: "0px",
                    borderBottomWidth: "1px",
                    backgroundColor: "#efefef",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    height: "40px",
                    paddingTop: "10px"
                  }}
                  onClick={() => {
                    var datos = [];
                    var dates = [];
                    var okstates = [];

                    client.data["60Days"].forEach(elemento => {
                      //dates.push(elemento["date"])
                      dates.push("");
                      datos.push(elemento["mean_elapsed"]);
                      okstates.push(elemento["ok_percent"]);
                    });

                    datosDatos = datos;
                    datosDates = dates;
                    datosStates = okstates;



                    var labels = datosDates;

                    var dataLine = {
                      labels,
                      datasets: [
                        {
                          label: 'Dataset 1',
                          data: datosDatos,
                          borderColor: 'rgb(0, 171, 135)',
                          backgroundColor: 'rgba(255, 99, 132, 0)',
                        }
                      ]
                    };
                    setChartData(dataLine);

                    var dataLine2 = {
                      labels,
                      datasets: [
                        {
                          label: 'Dataset 2',
                          data: datosStates,
                          borderColor: 'rgb(0, 171, 135)',
                          backgroundColor: 'rgba(255, 99, 132, 0)',
                        }
                      ]
                    };
                    setChartData2(dataLine2);


                    var temp = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false,
                      false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];

                      temp[contat] = !pulsadorGrafica[contat];

                    setpulsadorGrafica(temp);

                  } }
                >
                  <Col className="" style={{ textAlign: "start" }}>
                    <span>{client.description}</span>
                  </Col>
                  <Col style={{ textAlign: "end" }}>
                    {client.data.Actual["ok_status"] ?
                      <><span>UP</span><Image style={{ width: "18px", marginLeft: "5px", marginBottom: "3px" }} src={okflag} fluid /></>
                      :
                      <><span>DOWN</span><Image style={{ width: "18px", marginLeft: "5px", marginBottom: "3px" }} src={koflag} fluid /></>}
                  </Col>
                   {/*  <Col style={{ maxWidth: "45px", marginLeft: "-20px", marginBottom: "3px" }}><Image src={dropdown} fluid /></Col> */}
                </Row>
                <Row
                  style={{
                    marginRight: "5px",
                    marginLeft: "5px",
                    marginTop: "5px"
                  }}

                >

                  {client.data["60Days"].map(clientes => {
                    return (
                      (clientes.ok_percent < upLimit ?
                        <Col style={{ width: "10px", padding: 0 }}><Image src={KOSTATUS} fluid /></Col> :
                        (
                          clientes.ok_percent == 100 ?
                        <Col style={{ width: "10px", padding: 0 }}><Image src={OKSTATUS} fluid /></Col>:
                        <Col style={{ width: "10px", padding: 0 }}><Image src={WRSTATUS} fluid /></Col>
                        )
                      )
                    );
                  })}


                </Row>
                <Row
                  style={{
                    marginRight: "5px",
                    marginLeft: "5px",
                  }}
                >
                  <Col style={{ textAlign: "start", color: 'lightgray', padding: 0, fontSize: '10px' }}>
                    60 days ago
                  </Col>
                  <Col style={{ textAlign: "center", color: '#00ab87', padding: 0, fontSize: '12px' }}>
                    {client.data["60DaysMean"]["ok_status"]}% UPTIME.
                  </Col>
                  <Col style={{ textAlign: "end", color: 'lightgray', padding: 0, fontSize: '10px' }}>
                    Today
                  </Col>
                </Row>
              </Row>
              
              
              
              
              
              
              
              <Row><Col><Row style={{ marginLeft: "-30px", width: "387px" }}><Row className="mb-3" style={{
                      display: "block",
                      backgroundColor: "white",

                      borderLeftStyle: "solid",
                      borderRightStyle: "solid",
                      borderTopStyle: "solid",
                      borderBottomStyle: "solid",
                      borderTopWidth: "1px",
                      borderBottomWidth: "1px",
                      borderLeftWidth: "1px",
                      borderRightWidth: "1px",
                      borderTopColor: "#454545",
                      borderBottomColor: "#454545",
                      borderLeftColor: "#454545",
                      borderRightColor: "#454545",
                      borderBottomLeftRadius: "8px",
                      borderBottomRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                      textAlign: "start",
                      color: "#00ab87",
                      width: "100%"
                    }}
                      hidden={!pulsadorGrafica[contat]}
                    ><Row
                      style={{
                        borderBottomStyle: "solid",
                        borderBottomColor: "#454545",
                        marginRight: "0px",
                        marginLeft: "0px",
                        borderBottomWidth: "1px",
                        backgroundColor: "#efefef",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        height: "40px",
                        paddingTop: "10px",
                        color: "black",
                        marginBottom: "15px",
                        paddingLeft: "10px"
                      }}>TOTAL UPTIME FOR LAST 60 DAYS</Row><h3 style={{ textAlign: "center" }}>{client.data["60DaysMean"]["ok_status"]}%</h3></Row></Row>
                      <Row className="mb-3" style={{
                        display: "block",
                        backgroundColor: "white",

                        borderLeftStyle: "solid",
                        borderRightStyle: "solid",
                        borderTopStyle: "solid",
                        borderBottomStyle: "solid",
                        borderTopWidth: "1px",
                        borderBottomWidth: "1px",
                        borderLeftWidth: "1px",
                        borderRightWidth: "1px",
                        borderTopColor: "#454545",
                        borderBottomColor: "#454545",
                        borderLeftColor: "#454545",
                        borderRightColor: "#454545",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                        textAlign: "start",
                        color: "#00ab87",
                        width: "387px",
                        marginLeft: "-45px",
                        height: "200px",
                        overflow: "auto"
                      }}
                        hidden={!pulsadorGrafica[contat]}
                      ><Row
                        style={{
                          borderBottomStyle: "solid",
                          borderBottomColor: "#454545",
                          marginRight: "0px",
                          marginLeft: "0px",
                          borderBottomWidth: "1px",
                          backgroundColor: "#efefef",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          height: "40px",
                          paddingTop: "10px",
                          color: "black",
                          marginBottom: "15px",
                          paddingLeft: "10px"
                        }}>PAST INCIDENTS
                        </Row>
              {client.messages.map(clientess => {
                    
                    return (
                      <Row style={{marginRight: "0px",marginLeft: "0px",paddingTop: "0px",}}>
                        {clientess["ok_status"] ?
                        <><Col style={{color: "green", textAlign:"start", marginRight: "-45px"}}><Image style={{width: "5px", marginRight:"5px"}} src={OKSTATUS} fluid />{clientess["message"]}</Col><Col style={{color: "black", textAlign:"end", paddingTop:"5px"}}>{clientess["date"]}</Col></>:
                        <><Col style={{color: "red", textAlign:"start", marginRight: "-45px"}}><Image style={{width: "5px", marginRight:"5px"}} src={KOSTATUS} fluid />{clientess["message"]}</Col><Col style={{color: "black", textAlign:"end", paddingTop:"5px"}}>{clientess["date"]}</Col></>
                    }
                        </Row>
                    );
                    
                  })}
              </Row>

                    </Col><Col><Row style={{ marginLeft: "10px", width: "387px" }}><Row className="mb-3" style={{
                      display: "block",
                      backgroundColor: "white",
                      width: "100%",
                      borderLeftStyle: "solid",
                      borderRightStyle: "solid",
                      borderTopStyle: "solid",
                      borderBottomStyle: "solid",
                      borderTopWidth: "1px",
                      borderBottomWidth: "1px",
                      borderLeftWidth: "1px",
                      borderRightWidth: "1px",
                      borderTopColor: "#454545",
                      borderBottomColor: "#454545",
                      borderLeftColor: "#454545",
                      borderRightColor: "#454545",
                      borderBottomLeftRadius: "8px",
                      borderBottomRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                      textAlign: "start",
                      color: "#00ab87",
                    }}
                      hidden={!pulsadorGrafica[contat]}
                    ><Row
                      style={{
                        borderBottomStyle: "solid",
                        borderBottomColor: "#454545",
                        marginRight: "0px",
                        marginLeft: "0px",
                        borderBottomWidth: "1px",
                        backgroundColor: "#efefef",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        height: "40px",
                        paddingTop: "0px",
                        color: "black",
                      }}><Col style={{ borderRightStyle: "solid", borderRightWidth: "1px", paddingTop: "10px", textAlign: "center" }}>DAY</Col><Col style={{ borderRightStyle: "solid", borderRightWidth: "1px", paddingTop: "10px", textAlign: "center" }}>DATE</Col><Col style={{ paddingTop: "10px", textAlign: "center" }}>UPTIME</Col></Row>

                      {lastWeek.map(clientess => {
                        conta++;
                        return (
                          <Row style={{ marginRight: "0px", marginLeft: "0px", height: "40px", paddingTop: "0px", }}><Col style={{ color: "black", borderRightStyle: "solid", borderRightWidth: "1px", paddingTop: "10px", textAlign: "center" }}>{diasDash[conta]}</Col><Col style={{ color: "black", borderRightStyle: "solid", borderRightWidth: "1px", paddingTop: "10px", textAlign: "center" }}>{clientess["date"]}</Col><Col style={{ color: "black", paddingTop: "10px", textAlign: "center" }}>{clientess["ok_percent"]}%</Col></Row>
                        );

                      })}


                    </Row></Row></Col></Row>
              
              
              
              
              
              
              <Row className="mb-3" style={{
                display: "block",
                backgroundColor: "white",
                marginRight: "-45px",
                marginLeft: "-45px",
                borderLeftStyle: "solid",
                borderRightStyle: "solid",
                borderTopStyle: "solid",
                borderBottomStyle: "solid",
                borderTopWidth: "1px",
                borderBottomWidth: "1px",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderTopColor: "#454545",
                borderBottomColor: "#454545",
                borderLeftColor: "#454545",
                borderRightColor: "#454545",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                textAlign: "start",
                color: "#00ab87"
              }}
                hidden={!pulsadorGrafica[contat]}
              >

                  {pulsadorGrafica[contat] ?
                    <><Row
                      style={{
                        borderBottomStyle: "solid",
                        borderBottomColor: "#454545",
                        marginRight: "0px",
                        marginLeft: "0px",
                        borderBottomWidth: "1px",
                        backgroundColor: "#efefef",
                        height: "40px",
                        paddingTop: "10px",
                        color: "black",
                        marginBottom: "15px",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}>

                      <Col className="" style={{ textAlign: "start" }}>
                        <span>System Metrics</span>
                      </Col>

                    </Row><span style={{ paddingLeft: "10px", textAlign: "start" }}>Respond Time</span><Col md="12" className="p-0 pt-3">
                        <Line data={chartData} options={options} height={6} width={40} />
                      </Col><span style={{ paddingLeft: "10px", textAlign: "start" }}>UP Time</span><Col md="12" className="p-0 pt-3">
                        <Line data={chartData2} options={options2} height={6} width={40} />
                      </Col></>
                    :
                    <Row></Row>}</Row></></>
      
                    
      
                  )}
                )}
      
                  </Col>
                  
                </Row>
















        </div>
      </Row>
      <Row className="mt-auto">
        <Col className="px-0">
          {/*<Footer/>*/}
        </Col>
      </Row>
    </Container>
  );
}


export default MainPage;
