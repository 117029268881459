import React, { FunctionComponent, useState, useEffect, Fragment } from 'react'; 
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import foot1 from '../../assets/img/llamanos.png';
import foot2 from '../../assets/img/contactanos.png';
import foot3 from '../../assets/img/siguenos.png';

const PricingFooter:FunctionComponent<{}> = () => {
  return (
    <Container fluid className="pricing-footer pt-4 pb-2">

      <Row className="pricing-box-footer-real mx-auto">
        <Col className="footer-column text-center mx-auto">
          <Row>
          <Col md="4" className="text-center">
            <Row>
              <Col className="footer-image"><Image src={foot1} fluid/></Col>
              <Col className="footer-text">
                <Row className="footer-title">Llámanos</Row>
                <Row className="footer-description">T: +34 646 420 488</Row>
              </Col>
            </Row>
            </Col>
          <Col md="4" className="text-center" style={{paddingLeft: '3%', paddingTop: '0.2%'}}>
          <Row>
              <Col className="footer-image"><Image src={foot2} fluid/></Col>
              <Col className="footer-text">
                <Row className="footer-title">Contáctanos</Row>
                <Row className="footer-description">centuriapp@nogalesinvestments.com</Row>
              </Col>
            </Row>
            </Col>
            {/*
          <Col md="3" className="text-center">
          <Row>
              <Col className="footer-image"><Image src={foot3} fluid/></Col>
              <Col className="footer-text">
                <Row className="footer-title">Síguenos</Row>
                <Row className="footer-description"></Row>
              </Col>
            </Row>
            </Col>
            */}
          <Col md="4" className="text-center">
          <Row>
              <Col className="footer-image"><Image src={foot3} fluid/></Col>
              <Col className="footer-text">
                <Row className="footer-title-ent">© 2021 CENTURIAPP</Row>
                <Row className="footer-description">Una compañía ...</Row><Row className="footer-description">NOGALES INTERNATIONAL</Row>
              </Col>
            </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default PricingFooter;