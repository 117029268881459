import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import Logo from '../../assets/img/centuriappblanco.png';

type PricingMenuProps = {
  active: string,

}

const PricingMenu:FunctionComponent<PricingMenuProps> = props => {
  return (
    <Row className={'py-0 pricing-header'} style = {{paddingLeft: '26px'}} id="header">
      <div className="p-0 mx-auto pricing-box" style={{width : '1020px' }}>
        <Row className="menu-header " style={{paddingLeft:'7px'}}>
          <Col className="logo-col text-left">
            <a href="" className="my-auto">
              <img className="logo-pricing" src={Logo} />
            </a>
          </Col>
          <Col className="links-box">
            <Row className="header-menu-options">
            <a href="https://raulnogales.wixsite.com/centuriapp" className="link link-header" style={{marginRight:'71px'}}>INICIO</a>
            <a href="https://raulnogales.wixsite.com/centuriapp/servicios" className="link link-header" style={{marginRight:'71px'}} >CARACTERÍSTICAS</a>
            
            { props.active=="pricing" ?
            <a href="#" className="link link-header link-active" style={{marginRight:'71px'}}>PRICING</a>:
            <a href="/Plans" className="link link-header" style={{marginRight:'71px'}}>PRICING</a>
            }
            { props.active=="login" ?
            <a href="/login" className="link link-header link-active" style={{marginRight:'71px'}}>LOGIN</a> : 
            <a href="/login" className="link link-header" style={{marginRight:'71px'}}>LOGIN</a> 
            }

            {/*<a href="https://raulnogales.wixsite.com/centuriapp/contacto" className="link link-header">CONTACTO</a>*/}
            </Row>
          </Col>
        </Row>
      </div>
    </Row>
  );
}

export default PricingMenu;