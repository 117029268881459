import React, { FunctionComponent, useState, useEffect } from 'react';
import DashLayout from '../../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../../configs/routers/dashboard-routers.json';
import { Row, Col, Table, Spinner } from 'react-bootstrap';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { parseToCoin } from '../../../services/AmountService';

type BalanceProps = {

}


const BalanceContent:FunctionComponent<BalanceProps> = (props) => {

  const [payments, setPayments] = useState([]);
  const [stillLoaded, SetStillLoaded] = useState(false);

  const getPayments = async () => {
    SetStillLoaded(true);
    try {
      const result = await ObexRequestHandler.get('/affiliate_payments',{},true);
      const { success, data } = result;
      if (success) {
        console.log(data);
        setPayments(data);
        SetStillLoaded(false);
      } else {
        console.log('Cannot get the account settings information');
        SetStillLoaded(false);
      }
    } catch (error) {
      console.error('ERROR FETCHING ACCOUNT SETTINGS ', error);
      SetStillLoaded(false);
    }
    SetStillLoaded(false);
  }

  useEffect(()=>{
    getPayments()
  }, [])

  return (
    <DashLayout active="balance" sider={DashRouters}>
        <Col md="12" className="mx-auto">
          <Row>
            <Col>
            <h1 className="mb-3">Historial de pagos</h1>
            </Col>
          </Row>
        </Col>
      {stillLoaded ?
        <Row className="h-100" >
          <Col md="1" className="mx-auto d-flex align-items-center" style={{ marginTop: '15em' }}>
            <Spinner animation="border"/>
          </Col>
        </Row>
        :
      <Row>
        <Col>
          <Table responsive>
            <thead>
              <tr>
                <th>Tipo</th>
                <th>NETO</th>
                <th>Cantidad</th>
                <th>Comision</th>
                <th>Descripcion</th>
                <th>Fecha</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
            {payments.map(payment => (
              <tr>
                <td>Pago</td>
                <td>({parseToCoin(payment.amount)})</td>
                <td>{parseToCoin(payment.amount)}</td>
                <td> - </td>
                <td>{payment.description}</td>
                <td>{payment.date}</td>
              </tr>
              ))}
            </tbody>
            
          </Table>
        </Col>
      </Row>
    }
    </DashLayout>
  )
}

export default BalanceContent;