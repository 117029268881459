import React, { FunctionComponent, useState } from 'react'; 
import { Row, Col, Container, Image, Button } from 'react-bootstrap';
import Logo from '../../assets/img/centuriappazul.png';
import ImgSerpentina from '../../assets/img/ilustracion-serpentina.png';

function routeLogin() {
  const path = '/login';
  window.location.href = path;
}

const handleLogIn = async (event) => {
routeLogin();

}

const VerifySignUp:FunctionComponent<{}> = () => {


  return(
    <Container fluid>
      <Row className="pt-3 verify-signup">
        <Col md="6" className="border rounded px-5 pt-3 pb-4 login-form mx-auto">
          <Row className="justify-content-center my-4">
            <Col xs="6">
              <Image src={Logo} fluid/>
            </Col>
          </Row>
          <Row className="">
            <Col className="my-2 text-center">
              <h1 className="mb-2">Cuenta creada</h1>
              <span className="verify-text-content">Enhorabuena! Su cuenta ha sido creada satisfactoriamente. Se ha enviado un email de bienvenida con los datos para el acceso a su portal privado, así como el código que representa a su empresa.</span>
            </Col>
          </Row>
          <Row className="my-5">
            <Col md="4" className="mx-auto">
              <Image src={ImgSerpentina} fluid/>
            </Col>
          </Row>
          <Row  className="">
            <Col md="6" className="mx-auto">
              <Button type="submit" onClick={handleLogIn} className="btn-block btn-green">Accede a tu portal privado</Button> 
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}


export default VerifySignUp;