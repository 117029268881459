import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { useHistory } from 'react-router-dom';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import SessionService from '../../services/SessionsService';
import SendCommunicationModal from './SendCommunication';
import SendGroupCommunicationModal from './SendGroupCommunication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faEdit, faEnvelope, faEnvelopeOpenText, faEraser, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

type ClientsProps = {}



const ClientCommunications:FunctionComponent<ClientsProps> = (props) => {

  const history = useHistory();

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState(Array());
  const [showGroup, setShowGroup] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseGroup = () => setShowGroup(false);
  const [clientToSend, setClientName] = useState('');
  const [clientIDToSend, setClientID] = useState(0);  
  const [stillLoaded, SetStillLoaded] = useState(false);

  const getClients = async () => {
    setLoading(true);
    SetStillLoaded(true);
    try {
      const { status,resultado,message} = await ObexProjectsRequestHandler.get(`/ws/employee_time_control.py/get_employees?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29`);

      if (!status) throw { message };
      const clientsList = resultado.sort((a, b) => a.id > b.id ? 1 : -1);

      setClients(clientsList);
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
      SetStillLoaded(false);
    };
    SetStillLoaded(false);
  };

  const handleClientDetail = (event, client) => {
    event.preventDefault();
    event.stopPropagation();
    setClientName(client.nombre);
    setClientID(client.nick);
    setShow(true);
  }



  const handleSendGroupNotification = (event) => {
    event.preventDefault();
    event.stopPropagation();
    //setClientName(client.nombre);
    //setClientID(client.nick);
    setShowGroup(true);
  }



  useEffect(()  => {
    getClients();
  },[]);


  return(

      <><SendCommunicationModal
      show={show}
      handleClose={handleClose}
      nameProject={clientToSend}
      planID={clientIDToSend}
      priceMonthly={0}
      priceYear={0} />

      <SendGroupCommunicationModal
      show={showGroup}
      handleClose={handleCloseGroup}
      nameProject={"Todo el Grupo"}
      planID={clientIDToSend}
      priceMonthly={0}
      priceYear={0} />
      
      <DashLayout sider={DashRouters} active="messages">
        <Row>
          <Col md="9" className="mx-auto">
            <Row>
              <Col>
                <h1 className="mb-3">Listado de empleados</h1>
              </Col>
              <Col className="ml-4 mr-4">
              <Button onClick={(event) => handleSendGroupNotification(event)} type="submit" className="btn-block obex-btn btn-green">
                      Enviar al grupo
                    </Button>
              </Col>
            </Row>
            <Row className="mb-3 border-bottom">
              <Col>Nombre</Col>
              <Col>Apellidos</Col>
              <Col>Teléfono</Col>
              <Col md="2">Acción</Col>
            </Row>

            {stillLoaded ?
            <Row className="h-100" >
              <Col md="1" className="mx-auto d-flex align-items-center" style={{ marginTop: '15em' }}>
                <Spinner animation="border"/>
              </Col>
            </Row>
            :

            <>
            {clients.map(client => {
              return (
                <Row className="mb-3 pt-1">
                  <Col className="">
                    <span>{client.nombre}</span>
                  </Col>
                  <Col className="">
                  <span>{client.apellido1} {client.apellido2}</span>
                </Col>
                <Col className="">
                    <span>{client.nick}</span>
                  </Col>
                  <Col md="2" className="">

                    <Row>
                    <Col md="2">
                    <Button onClick={(event) => handleClientDetail(event, client)} type="submit" className="btn-transparent">
                    <FontAwesomeIcon icon={faPaperPlane}/>
                    </Button>
                    </Col>  
                    </Row>
                  </Col>
                  {error.length > 0 && (
                <Alert variant='danger'>
                  {error}
                </Alert>)}
                </Row>
                

              );
            }
            )}
            </>
          }
          </Col>
        </Row>
      </DashLayout></>
  )
}

export default ClientCommunications;