import React, { FunctionComponent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Image, Form, Button, Spinner, Alert } from 'react-bootstrap';
import PasswordRecovery from '../Modals/passwordRecovery';
import { useParams } from 'react-router-dom';
import Logo from '../../assets/img/xdefinitive-logo.png';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import Footer from '../functionalComponents/DashboardLayout/FooterMenu';
import Cookies from 'universal-cookie';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';

type ValidateAccountProps = {
  userLabel?: string,
  passLabel?: string,
  rememberCheckboxLabel?: string,
  submitButtonLabel?: string,
  missingUserMsg?: string,
  missingPassMsg?: string,
  loginFailedMsg?: string
}

const ValidateAccount:FunctionComponent<{}> = () => {

  const { status } = useParams();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      setSuccess('');
      setError('');
      
    

      
    } catch (error) {

    }
    setLoading(false);
  };
  
  const handleFirstPass = (e) => {
    const { value } = e.target;
    setPassword(value);
  }

  const handleSecondPass = (e) => {
    const { value } = e.target;
    setRepeatPassword(value);
  }

  return(
    <Container fluid className="text-center d-flex flex-column min-vh-100">
      <Row className="justify-content-center pt-5 mt-5">
        <div className="rounded px-5 pb-4" style={{ width : '800px'}}>
          <Row className="justify-content-center my-4 pb-5">
            <Col xs="4">
              <Image src={Logo} fluid/>
            </Col>
          </Row>
          <Row>
            <Col>
              {status=="true" ?
              <div><b>Account verified Successfully</b></div>:
              <div><b>Can not verify your account</b></div>}
            </Col>
          </Row>
        </div>
      </Row>
      <Row className="mt-auto">
        <Col className="px-0">
          <Footer/>
        </Col>
      </Row>
    </Container>
  );
}


export default ValidateAccount;