import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Container, Row, Col,  Modal, Button, Form, Spinner, Card, Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { prependOnceListener } from 'process';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { parseToCoin } from '../../services/AmountService';
import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import SessionService from '../../services/SessionsService';
import { message } from 'antd';


type AddonsProps = {
  show: boolean,
  handleClose: any,
  nameProject: string,
  planID: string,
  priceMonthly: string,
  priceYear: string
}

const SignUpResult:FunctionComponent<AddonsProps> = props => {

  const { show, handleClose, nameProject, planID, priceMonthly, priceYear } = props;


  const [pricePlanMontly, setPricePlanMontly] = useState(priceMonthly);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [projecstPlanInfo, setProjecstPlanInfo] = useState([]);
  const [requestsPlanInfo, setRequestsPlanInfo] = useState([]);
  const [selectedProject, setProject] = useState(0);
  const [selectedTx, setTx] = useState(0);
  const [projectPlanPriceMonthly, setProjectPriceMonthly] = useState(0);
  const [projectPlanPriceYear, setProjectPriceYear] = useState(0);
  const [txPlanPriceMonthly, setTxPriceMonthly] = useState(0);
  const [txPlanPriceYear, setTxPriceYear] = useState(0);
  const [txDesc, setTxDesc] = useState('');
  const [projectDesc, setProjectDesc] = useState('');
  var [Message, setMessage] = useState(priceYear);
  const [sending, setSending] = useState(false);
  var [Subject, setSubject] = useState(priceMonthly);
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const history = useHistory();

const Cierra = (e) =>{
  setMessage('');
  setSubject('');
  handleClose()
}

  const changeSubject = (e) => {
    const newSubject = e.target.value;
    setSubject(newSubject);
  }

  const changeMessage = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
  }


  useEffect(() => {
    setMessage('');
    setSubject('');
  }, [])
  
  return(

    

    <Modal dialogClassName="addons-dialog w-75 obex-dialog py-3 obex-dialog"
      show={show} 
      onHide={Cierra}
      animation={false}>
      <Modal.Header closeButton className="border-0 pb-0 pt-4">
        <Modal.Title className=" w-100">
        {priceMonthly!='' ?
        <>
          <Row>
            


            <Col className="mx-auto" style={{textAlign:'center'}}>
              <h3><span >Se ha dado de alta al Empleado</span></h3>
            </Col>
          </Row>
          <Row></Row>
          <Row className="pt-3"> 
            <Col md="10" className="mx-auto  modal-subtitle">
            
            <Row>
            <Col md="3"></Col>
              <Col md="3"><h4>Nombre</h4></Col>
              <Col md="6">{nameProject}</Col>
              </Row>            
            <Row>
            <Col md="3"></Col>
            <Col md="3"><h4>Teléfono</h4></Col>
              <Col md="6">{planID}</Col>
              </Row>   
            </Col>
          </Row>
          </>
          :
          <>
          <Row>
            
          <Col className="mx-auto" style={{textAlign:'center'}}>
            <h3><span >NO se ha dado de alta al Empleado</span></h3>
          </Col>
        </Row>
        <Row></Row>
        <Row className="pt-3"> 
          <Col md="10" className="mx-auto  modal-subtitle">
          
          <Row>
          <Col md="2"></Col>
            <Col md="2"><h4>Motivo</h4></Col>
            <Col md="6">{priceYear}</Col>
            </Row>            
          </Col>
        </Row>
        </>
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-4">
        <Container fluid>

          <Row className="mb-2">
            <Col className="text-center">
              
              <Button className="btn-green with-add" onClick={handleClose}>
              {sending ? "Cerrando" : "Cerrar"}
                {sending && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
              </Button>
               
            </Col>
          </Row>

          {error.length > 0 && (
                <Alert variant='danger'>
                  {error}
                </Alert>)}
          {success.length > 0 && (
                <Alert variant='success'>
                  {success}
                </Alert>)}   



        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default SignUpResult;